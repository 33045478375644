export default function ZealyLogo() {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
      <path
        d="M21.9,12.5l1.3-1.3V5.7l-4.6-4.6c0,0-3.2,1.3-8.9,1.9C5.3,3.5,1,3.2,1,3.2v6.5l1.7,1.7l-2.3,1.5v5.4L5,22.9
      c0,0,4.6,0.4,9.5-0.1s9.1-2,9.1-2v-6.5L21.9,12.5z M21.9,19.6c0,0-3.8,1-7.5,1.4s-7.5,0.2-7.5,0.2s0-1.3,0-2.6
      c2.6-1.6,5.3-3.5,8.7-6.3c-3.4,0.5-8,0.4-8,0.4v-3c0,0,3.3,0.1,6.7-0.2s7.3-1.4,7.3-1.4v2.7c0,0-4.6,4.4-8.7,7.4
      c4.8-0.2,9-1.6,9-1.6V19.6L21.9,19.6z"
      />
    </svg>
  )
}
