import { AssetProps } from './index'

export default function TwitterLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24 4.30888C23.117 4.70049 22.168 4.96517 21.172 5.08421C22.1886 4.47479 22.9693 3.50984 23.3369 2.3601C22.3856 2.92431 21.3318 3.33409 20.2102 3.55497C19.312 2.598 18.0323 2 16.6162 2C13.8968 2 11.6921 4.20459 11.6921 6.92378C11.6921 7.30969 11.7358 7.6855 11.8197 8.04587C7.72742 7.84054 4.09929 5.88026 1.67076 2.90131C1.24692 3.62853 1.0041 4.47436 1.0041 5.37671C1.0041 7.08496 1.87346 8.59208 3.19464 9.47504C2.38745 9.44949 1.62827 9.228 0.964337 8.85921C0.963986 8.87975 0.963986 8.90038 0.963986 8.9211C0.963986 11.3068 2.66125 13.2969 4.91377 13.7492C4.50056 13.8617 4.06558 13.9219 3.61646 13.9219C3.2992 13.9219 2.99072 13.8911 2.69014 13.8336C3.31667 15.7898 5.13508 17.2133 7.28972 17.2531C5.60456 18.5737 3.48144 19.3609 1.17459 19.3609C0.777175 19.3609 0.385208 19.3376 0 19.2921C2.17904 20.6892 4.76726 21.5043 7.5479 21.5043C16.6047 21.5043 21.5572 14.0014 21.5572 7.49474C21.5572 7.28125 21.5525 7.06889 21.543 6.85776C22.505 6.16355 23.3398 5.2963 24 4.30888Z"
        fill={color ?? '#55ACEE'}
      />
    </svg>
  )
}
