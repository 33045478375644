// @TODO fix alias
import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { NumberFormatter } from '../Formatters'
import { TextS, TextSNumeric } from '../Typo/Text'

export enum ProgressBarColor {
  PINK = 'bg-pink-500',
  NEUTRAL = 'bg-neutral-400',
  GREEN = 'bg-green-900',
}

interface TextSizeProps {
  children: ReactNode
  isBold?: boolean
}

export interface ProgressBarProps {
  TextSize?: FC<TextSizeProps>
  label?: string
  numerator: number
  denominator: number
  ofNumberFormatOptions?: Intl.NumberFormatOptions
  ofLabel?: string
  valueLeftLabel?: string | ReactNode
  isLeftValue?: boolean
  color?: ProgressBarColor
  className?: string
}

export function ProgressBar({
  TextSize = TextS,
  label,
  numerator,
  denominator,
  ofNumberFormatOptions,
  ofLabel,
  valueLeftLabel,
  className,
  isLeftValue = false,
  color = ProgressBarColor.PINK,
}: ProgressBarProps) {
  const progress = (numerator / denominator) * 100

  return (
    <div className={clsx('flex flex-col gap-4 text-white', className)}>
      <div className="flex justify-between">
        {label && <TextSize isBold>{label}</TextSize>}

        {ofLabel && (
          <TextSize isBold>
            <TextSNumeric tag="span" isBold>
              <NumberFormatter value={numerator} />
            </TextSNumeric>
            &nbsp;{ofLabel}&nbsp;
            <TextSNumeric tag="span" isBold>
              <NumberFormatter
                value={denominator}
                options={ofNumberFormatOptions}
              />
            </TextSNumeric>
          </TextSize>
        )}
      </div>

      <div className="h-2 w-full rounded-full bg-neutral-600">
        <div
          className={clsx('h-2 rounded-full', color)}
          style={{ width: `${progress}%` }}
        />
      </div>

      {valueLeftLabel &&
        (isLeftValue ? (
          <TextSize>
            <NumberFormatter value={denominator - numerator} /> {valueLeftLabel}
          </TextSize>
        ) : (
          <TextSize>{valueLeftLabel}</TextSize>
        ))}
    </div>
  )
}
