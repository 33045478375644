export interface HeroImageProps {
  src: string
  alt?: string
}

export const HeroImage = ({ src, alt }: HeroImageProps) => (
  <img
    src={src}
    alt={alt}
    key={src}
    className="h-full w-full rounded-[10px] object-cover"
  />
)
