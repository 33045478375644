import { AssetProps } from './index'

export default function WalletConnectLogo({ size, className }: AssetProps) {
  return (
    <svg
      width={size ?? '28.724'}
      height={size ?? '17.605'}
      viewBox="0 0 28.724 17.605"
      className={className}
    >
      <defs>
        <clipPath id="a">
          <rect
            data-name="Rectangle 1449"
            width="28.724"
            height="17.605"
            fill="none"
          />
        </clipPath>
      </defs>
      <g data-name="Group 1448" transform="translate(0 0)">
        <g data-name="Group 1447" transform="translate(0 0)" clipPath="url(#a)">
          <path
            data-name="Path 3427"
            d="M53.9,3.441a12.177,12.177,0,0,1,16.963,0l.564.552a.579.579,0,0,1,0,.83L69.5,6.711a.3.3,0,0,1-.424,0l-.776-.76a8.495,8.495,0,0,0-11.834,0l-.831.813a.3.3,0,0,1-.424,0L53.281,4.877a.578.578,0,0,1,0-.83Z"
            transform="translate(-48.02 -0.001)"
            fill="#3b99fc"
          />
          <path
            data-name="Path 3428"
            d="M26.831,75.065l1.716,1.68a.579.579,0,0,1,0,.83l-7.739,7.577a.609.609,0,0,1-.848,0l-5.493-5.378a.152.152,0,0,0-.212,0L8.763,85.154a.609.609,0,0,1-.848,0L.175,77.576a.578.578,0,0,1,0-.83l1.716-1.68a.609.609,0,0,1,.848,0l5.493,5.378a.152.152,0,0,0,.212,0l5.493-5.378a.609.609,0,0,1,.848,0l5.493,5.378a.152.152,0,0,0,.212,0l5.493-5.378a.609.609,0,0,1,.848,0"
            transform="translate(0 -67.721)"
            fill="#3b99fc"
          />
        </g>
      </g>
    </svg>
  )
}
