import { AssetProps } from './index'

export default function ChevronRight16({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25754 15L4 13.8631L10.4849 8L4 2.13695L5.25754 1L13 8L5.25754 15Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
