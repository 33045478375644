import { H2 } from '../Typo/Header'
import { Card, CardProps } from '../Card'

export interface CardsSectionProps {
  title?: string
  cards: CardProps[]
}

export function CardsSection({ title, cards }: CardsSectionProps) {
  return (
    <section className="flex flex-col gap-8 font-sora lg:gap-12">
      {title && (
        <header>
          {' '}
          <H2 isBold>{title}</H2>{' '}
        </header>
      )}

      <main className="grid grid-cols-1 gap-8 md:grid-cols-3 md:flex-row lg:gap-12">
        {cards.map(card => (
          <Card key={card.title} {...card} />
        ))}
      </main>
    </section>
  )
}
