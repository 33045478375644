import { AssetProps } from './index'

export default function YoutubeMonoLogo({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.4955 6.64545C23.2227 5.60909 22.4045 4.79091 21.3682 4.50455C19.5 4 12 4 12 4C12 4 4.5 4 2.61818 4.50455C1.58182 4.77727 0.777273 5.59545 0.490909 6.64545C6.50233e-08 8.52727 0 12.4545 0 12.4545C0 12.4545 -3.25116e-08 16.3818 0.504545 18.2636C0.777273 19.3 1.59545 20.1182 2.63182 20.4045C4.5 20.9091 12 20.9091 12 20.9091C12 20.9091 19.5 20.9091 21.3818 20.4045C22.4182 20.1318 23.2227 19.3136 23.5091 18.2636C24 16.3818 24 12.4545 24 12.4545C24 12.4545 24 8.52727 23.4955 6.64545Z"
        fill={color ?? 'currentColor'}
      />
      <path
        d="M9.54541 16.0273V8.88184L15.8181 12.4546L9.54541 16.0273Z"
        fill={color ?? '#15121A'}
      />
    </svg>
  )
}
