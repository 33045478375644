import clsx from 'clsx'
import { HTMLAttributes } from 'react'

import { SuccessAlert, InfoAlert, ErrorAlert } from '../../assets/icons'
import { Button, ButtonVariant, Icon, IconName } from '..'
import { addressShortener } from '../../utils'

export enum ToastStatus {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export interface ToastProps extends HTMLAttributes<HTMLSpanElement> {
  status?: ToastStatus
  txLink?: string
  txHash?: string
}

const { SUCCESS, INFO, ERROR } = ToastStatus

function renderIcon(status: ToastStatus) {
  if (status === SUCCESS) return <SuccessAlert />
  if (status === ERROR) return <ErrorAlert />
  if (status === INFO) return <InfoAlert />
}

export function Toast({ children, txLink, txHash, status = INFO }: ToastProps) {
  return (
    <div
      className={clsx(
        'items-top grid w-[400px] grid-cols-[50px_1fr_auto] rounded-sm border bg-neutral-800 p-2 text-sm',
        status === SUCCESS && 'border-green-500',
        status === ERROR && 'border-red-500',
        status === INFO && 'border-blue-500'
      )}
    >
      <div className="mx-auto self-center">{renderIcon(status)}</div>

      <div className="self-center">
        <div>{children}</div>
        {txLink && (
          <div className="mt-2">
            Tx Hash:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={txLink}
              className="underline"
            >
              {txHash && addressShortener(txHash)}
            </a>{' '}
          </div>
        )}
      </div>
      <div>
        <Button
          variant={ButtonVariant.Ghost}
          isIcon
          onClick={() => console.log('clicked')}
        >
          <Icon name={IconName.Exit24} />
        </Button>
      </div>
    </div>
  )
}
