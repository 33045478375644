import { AssetProps } from './index'

export default function EverdomeLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_486_621)">
        <path
          d="M3.50392 3.53821C5.19937 1.84439 7.30926 0.71517 9.64521 0.225843C11.9812 -0.225843 14.3925 8.07678e-07 16.5777 0.903374C18.763 1.80675 20.6468 3.35001 21.9655 5.34495C23.2841 7.3399 24 9.63598 24 12.045C24 14.4163 23.2841 16.75 21.9655 18.745C20.6468 20.7399 18.763 22.2832 16.5777 23.1866C14.3925 24.0899 11.9812 24.3534 9.64521 23.8641C7.30926 23.4124 5.1617 22.2456 3.50392 20.5517C1.24333 18.2933 0 15.2444 0 12.045C0 8.84552 1.281 5.79664 3.50392 3.53821ZM20.3077 20.3635C21.9655 18.7073 23.0581 16.6371 23.5102 14.341C23.9623 12.045 23.7363 9.71126 22.832 7.56575C21.9278 5.42024 20.4584 3.57585 18.4992 2.29607C16.5777 0.978654 14.3171 0.301126 12.0188 0.301126C9.72057 0.301126 7.42229 0.978654 5.50078 2.25843C3.57928 3.53821 2.07221 5.3826 1.16798 7.52811C0.263736 9.67362 0.0376763 12.045 0.489796 14.3034C0.941915 16.5995 2.07221 18.6697 3.69231 20.3259C5.87755 22.509 8.854 23.7512 11.9812 23.7512C15.1083 23.7512 18.0848 22.5467 20.3077 20.3635Z"
          fill={color ?? 'url(#paint0_linear_486_621)'}
        />
        <path
          d="M4.52116 4.55452C6.81944 2.2208 9.41912 0.865742 12.0188 0.639899C14.9199 0.376416 17.708 1.50563 20.1193 3.91463C22.5306 6.32362 23.6609 9.14666 23.3971 12.045C23.1334 14.6422 21.7771 17.2394 19.4788 19.5731C17.1805 21.9068 14.5808 23.2619 11.9811 23.4877C9.08003 23.7888 6.29196 22.622 3.88066 20.213C1.46936 17.804 0.339059 14.9809 0.602795 12.0826C0.866532 9.44778 2.22289 6.85059 4.52116 4.55452ZM19.2904 19.3472C23.1334 15.5079 25.3187 9.48543 19.9309 4.10283C14.5808 -1.27977 8.55256 0.903384 4.70955 4.74272C0.866532 8.58205 -1.31871 14.6045 4.06904 19.9871C9.4568 25.3697 15.4474 23.1866 19.2904 19.3472Z"
          fill={color ?? 'url(#paint1_linear_486_621)'}
        />
        <path
          d="M5.53845 5.53315C7.34693 3.72641 9.53218 1.7691 12.0565 1.20449C14.6562 0.602243 17.1805 1.61854 19.7802 4.21573C22.3799 6.85057 23.3595 9.37249 22.7943 11.9697C22.2292 14.454 20.3077 16.6748 18.4615 18.4815C16.6531 20.3259 14.4678 22.2456 11.9435 22.8102C9.34379 23.4124 6.81946 22.3961 4.21977 19.7989C1.62009 17.1641 0.602818 14.6422 1.20564 12.045C1.77079 9.56069 3.6923 7.37754 5.53845 5.53315ZM18.2731 18.3309C22.832 13.7764 24.7912 9.67361 19.5918 4.44158C14.3925 -0.752814 10.2857 1.20449 5.72684 5.759C1.16797 10.3135 -0.791216 14.4163 4.44583 19.6484C9.6452 24.8804 13.7143 22.8854 18.2731 18.3309Z"
          fill={color ?? 'url(#paint2_linear_486_621)'}
        />
        <path
          d="M4.37049 8.65732C4.70958 8.31855 5.04867 7.97979 5.42544 7.64102C6.14129 6.92585 6.9325 6.17304 7.61068 5.45787C9.56986 3.42528 11.3783 1.54326 13.7143 1.50562C15.4474 1.50562 17.2936 2.52191 19.4035 4.62978C21.5134 6.73765 22.5306 8.61968 22.4929 10.3135C22.4553 12.6472 20.5714 14.4916 18.5746 16.4113C17.821 17.1264 17.0298 17.9169 16.314 18.6697C14.3925 20.6646 12.5463 22.5843 10.248 22.622C8.51492 22.622 6.66876 21.6057 4.55887 19.5354C2.44898 17.4276 1.43172 15.5455 1.46939 13.8141C1.50707 11.8568 2.78807 10.2382 4.37049 8.65732ZM6.74412 6.77529C6.36735 7.15169 5.99059 7.5281 5.61382 7.86687C3.65464 9.78653 1.77081 11.5556 1.77081 13.8141C1.77081 15.4703 2.7504 17.277 4.78493 19.3096C6.81947 21.3798 8.62795 22.3585 10.248 22.3208C12.471 22.2832 14.2418 20.4388 16.1256 18.4815C16.8415 17.7287 17.6327 16.9382 18.3862 16.2231C20.3454 14.341 22.1915 12.5343 22.2292 10.3135C22.2292 8.65732 21.2496 6.88821 19.2151 4.81798C17.1805 2.78539 15.3721 1.7691 13.7143 1.80674C11.4914 1.84438 9.72057 3.68877 7.79906 5.68371C7.45997 6.02248 7.12089 6.39888 6.74412 6.77529Z"
          fill={color ?? 'url(#paint3_linear_486_621)'}
        />
        <path
          d="M11.1523 3.23709C11.9058 2.48428 12.697 1.95732 13.6766 1.91967C15.1837 1.80675 16.8791 2.78541 19.0643 5.0062C21.2496 7.18935 22.2292 8.92081 22.1161 10.4264C22.0031 12.1579 20.4584 13.3248 18.8006 14.5669C18.0094 15.1691 17.1805 15.809 16.427 16.5618C15.6734 17.3147 15.0706 18.1427 14.4678 18.9332C13.2245 20.5894 12.0565 22.1326 10.3234 22.2456C8.8163 22.3585 7.12086 21.3798 4.93561 19.159C2.75037 16.9759 1.77078 15.2444 1.84613 13.7388C1.95916 12.0073 3.5039 10.8405 5.16167 9.5607C5.95288 8.95845 6.78177 8.31856 7.49762 7.60339C8.21348 6.88822 8.85398 6.06013 9.45681 5.23204C10.0596 4.51687 10.5871 3.8017 11.1523 3.23709ZM20.6468 12.6472C21.3249 11.9697 21.7771 11.2545 21.8524 10.3512C21.9278 8.95845 20.9858 7.26463 18.876 5.15676C16.7661 3.04889 15.0706 2.07024 13.7143 2.18316C12.1318 2.29608 11.0392 3.72642 9.75822 5.38261C9.15539 6.17306 8.51489 7.03879 7.76136 7.75396C7.00783 8.50677 6.17894 9.14666 5.38773 9.74891C3.72996 11.0287 2.29825 12.1203 2.18522 13.7012C2.10987 15.0939 3.05178 16.7877 5.16167 18.8956C7.27156 21.0034 8.92933 21.9821 10.3234 21.8692C11.9058 21.7562 12.9984 20.3259 14.2417 18.6697C14.8446 17.8416 15.4851 17.0135 16.2386 16.2607C16.9921 15.5079 17.821 14.868 18.6499 14.2658C19.4034 13.7388 20.0816 13.2118 20.6468 12.6472Z"
          fill={color ?? 'url(#paint4_linear_486_621)'}
        />
        <path
          d="M2.97647 12.2332C3.5793 11.6309 4.48353 11.1416 5.46313 10.6523C6.55575 10.0876 7.68605 9.4854 8.55261 8.61967C9.41917 7.75393 10.022 6.62472 10.5871 5.53314C11.3784 3.98988 12.1696 2.5219 13.4882 2.3337C14.7692 2.14549 16.427 3.12415 18.7253 5.38258C20.9859 7.67865 21.9655 9.33484 21.7771 10.6146C21.5887 11.9697 20.1193 12.7225 18.5746 13.5129C17.482 14.0775 16.314 14.6798 15.4474 15.5455C14.5809 16.4113 13.978 17.5781 13.4129 18.6697C12.6217 20.2129 11.8681 21.6809 10.5118 21.8691C9.23079 22.0573 7.57302 21.1163 5.31242 18.8202C3.05182 16.5618 2.07223 14.868 2.26061 13.5882C2.33597 13.0236 2.5997 12.5719 2.97647 12.2332ZM8.74099 8.80787C7.83675 9.71124 6.70645 10.3135 5.57616 10.9157C4.03141 11.7062 2.71273 12.4214 2.52435 13.6259C2.33597 14.7927 3.31556 16.4489 5.5008 18.632C7.68605 20.8152 9.30614 21.7938 10.4741 21.6056C11.6798 21.4174 12.3579 20.1 13.1492 18.5568C13.752 17.4276 14.3548 16.2607 15.2214 15.3573C16.1256 14.4539 17.2936 13.8517 18.4239 13.2494C19.9686 12.459 21.2873 11.7438 21.4757 10.577C21.6641 9.41012 20.6845 7.79157 18.5369 5.60842C16.3517 3.42527 14.7316 2.44662 13.5636 2.63482C12.3579 2.82302 11.6798 4.14044 10.8509 5.6837C10.2481 6.73764 9.64523 7.9045 8.74099 8.80787Z"
          fill={color ?? 'url(#paint5_linear_486_621)'}
        />
        <path
          d="M3.05177 12.6096C3.6546 12.0073 4.74722 11.6686 5.9152 11.3298C7.27155 10.9158 8.70327 10.5017 9.56983 9.59834C10.4741 8.69497 10.8885 7.30227 11.303 5.94721C11.7551 4.40395 12.2072 2.93597 13.2998 2.67248C14.3924 2.409 16.0125 3.35001 18.3485 5.72137C20.6844 8.09272 21.664 9.67362 21.4003 10.7652C21.1365 11.8568 19.6672 12.3085 18.1224 12.7601C16.7284 13.1742 15.3343 13.5882 14.4301 14.4916C13.5259 15.395 13.1114 16.7877 12.697 18.1804C12.2449 19.7236 11.7927 21.1916 10.7001 21.4928C9.6075 21.7939 7.98741 20.8152 5.65146 18.4439C3.31551 16.0725 2.33592 14.454 2.59965 13.3624C2.71268 13.0613 2.86339 12.8354 3.05177 12.6096ZM20.7598 11.2545C20.9105 11.104 21.0612 10.8781 21.0989 10.6523C21.3626 9.67362 20.383 8.13036 18.1224 5.87193C15.8995 3.6135 14.3171 2.63484 13.3375 2.89833C12.3956 3.12417 11.9811 4.51687 11.529 5.98485C11.1146 7.30227 10.6624 8.84553 9.72053 9.78654C8.77862 10.7276 7.27155 11.1792 5.95287 11.5933C4.48348 12.045 3.08945 12.459 2.86339 13.4C2.59965 14.3787 3.57925 15.9596 5.83984 18.218C8.10044 20.4765 9.64518 21.4551 10.6248 21.1916C11.5667 20.9658 11.9811 19.5731 12.3956 18.1051C12.81 16.75 13.2621 15.2444 14.204 14.3034C15.146 13.3624 16.653 12.9107 18.0094 12.4967C19.1397 12.1579 20.2323 11.8191 20.7598 11.2545Z"
          fill={color ?? 'url(#paint6_linear_486_621)'}
        />
        <path
          d="M12.6593 3.23708C12.7724 3.12415 12.9231 3.01123 13.0738 2.97359C14.0157 2.63483 15.5604 3.57584 18.0094 6.06011C20.4584 8.50675 21.4003 10.0124 21.0612 10.991C20.7221 11.932 19.2527 12.0826 17.5573 12.2708C16.0125 12.4214 14.2794 12.6096 13.4129 13.4753C12.5463 14.341 12.3579 16.0725 12.2072 17.6534C12.0188 19.3472 11.8681 20.8152 10.9262 21.154C9.98429 21.4927 8.47723 20.5517 6.02825 18.1051C3.57927 15.6585 2.63735 14.1152 2.97644 13.1742C3.31553 12.2332 4.78492 12.0826 6.48037 11.8568C8.02511 11.6686 9.75823 11.4804 10.5871 10.6146C11.4537 9.74889 11.6421 8.01742 11.7928 6.47416C11.9435 5.08146 12.0942 3.83932 12.6593 3.23708ZM20.6091 11.1792C20.6844 11.1039 20.7598 10.991 20.7975 10.8781C21.0989 10.0877 20.1193 8.54439 17.821 6.24832C15.5228 3.95225 14.0157 2.97359 13.1868 3.23708C12.4333 3.50056 12.2449 4.96854 12.0942 6.5118C11.9058 8.0927 11.7174 9.89945 10.7755 10.8028C9.83358 11.7062 8.06278 11.932 6.48037 12.1202C4.93563 12.3084 3.50391 12.459 3.24018 13.2118C2.93876 14.0399 3.91836 15.5455 6.25431 17.8416C8.55258 20.1377 10.0596 21.1163 10.8509 20.8152C11.6044 20.5517 11.7551 19.0837 11.9435 17.5405C12.1319 15.9596 12.3202 14.1528 13.2245 13.2118C14.1664 12.2708 15.9372 12.0826 17.5573 11.932C18.8383 11.8191 20.0816 11.7062 20.6091 11.1792Z"
          fill={color ?? 'url(#paint7_linear_486_621)'}
        />
        <path
          d="M12.584 3.4629C12.6593 3.38762 12.7347 3.34998 12.8477 3.2747C13.6766 2.89829 15.146 3.87695 17.6326 6.39886C20.157 8.92078 21.0989 10.3888 20.7221 11.1792C20.383 12.0073 18.7629 11.932 16.9168 11.8191C15.146 11.7438 13.1491 11.6309 12.3579 12.4213C11.5667 13.2118 11.6797 15.2067 11.7551 16.9759C11.8681 18.8202 11.9435 20.4011 11.1146 20.7775C10.3234 21.1539 8.81631 20.1753 6.32965 17.691C3.843 15.1691 2.86341 13.7011 3.24017 12.873C3.61694 12.0449 5.19936 12.1202 7.00783 12.1955C8.74096 12.2708 10.7378 12.3837 11.529 11.5933C12.3202 10.8028 12.2072 8.80786 12.1319 7.07639C12.0942 5.45785 12.0565 4.02751 12.584 3.4629ZM20.383 11.2545C20.4207 11.2169 20.4584 11.1416 20.4961 11.1039C20.7975 10.464 19.7802 8.95842 17.4819 6.62471C15.1837 4.32863 13.6389 3.2747 12.9984 3.57582C12.3202 3.87695 12.4333 5.49549 12.5086 7.07639C12.584 8.95842 12.697 10.9534 11.8304 11.8191C10.9639 12.6848 9.00469 12.6095 7.12086 12.4966C5.53845 12.4213 3.91835 12.3461 3.61694 12.986C3.31553 13.6258 4.3328 15.1691 6.66874 17.4652C8.96702 19.7989 10.4741 20.8152 11.1146 20.5141C11.7551 20.2129 11.6797 18.5944 11.6044 16.9759C11.4914 15.0562 11.416 13.0989 12.2826 12.1955C13.1491 11.3298 15.146 11.4051 17.0298 11.518C18.4238 11.6309 19.8932 11.7438 20.383 11.2545Z"
          fill={color ?? 'url(#paint8_linear_486_621)'}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_486_621"
          x1="20.5263"
          y1="3.56309"
          x2="3.4922"
          y2="20.5432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#414FA2" />
          <stop offset="1" stopColor="#EE4492" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_486_621"
          x1="20.1688"
          y1="3.91835"
          x2="3.85092"
          y2="20.1845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#574EA0" />
          <stop offset="1" stopColor="#D84594" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_486_621"
          x1="19.8065"
          y1="4.27475"
          x2="4.20623"
          y2="19.8256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C4C9E" />
          <stop offset="1" stopColor="#C34796" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_486_621"
          x1="19.4454"
          y1="4.63113"
          x2="4.56205"
          y2="19.4673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#824B9C" />
          <stop offset="1" stopColor="#AD4898" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_486_621"
          x1="19.0901"
          y1="4.98757"
          x2="4.93109"
          y2="19.1017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#984A9A" />
          <stop offset="1" stopColor="#984A9A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_486_621"
          x1="18.7345"
          y1="5.353"
          x2="5.28559"
          y2="18.7592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD4898" />
          <stop offset="1" stopColor="#824B9C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_486_621"
          x1="18.3799"
          y1="5.71252"
          x2="5.64964"
          y2="18.4024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C34796" />
          <stop offset="1" stopColor="#6C4C9E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_486_621"
          x1="18.02"
          y1="6.07118"
          x2="6.00555"
          y2="18.0475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D84594" />
          <stop offset="1" stopColor="#574EA0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_486_621"
          x1="17.6681"
          y1="6.43225"
          x2="6.37458"
          y2="17.6899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE4492" />
          <stop offset="1" stopColor="#414FA2" />
        </linearGradient>
        <clipPath id="clip0_486_621">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
