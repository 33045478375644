import { AssetProps } from './index'

export default function PancakeLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_64_318)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.31546 3.75C3.93691 1.8 5.4511 0 7.41956 0C9.16088 0 10.5994 1.425 10.5994 3.15V7.05C11.0536 7.05 11.5079 6.975 11.9621 6.975C12.4164 6.975 12.795 6.975 13.2492 7.05V3.15C13.2492 1.425 14.6877 0 16.429 0C18.3975 0 19.9117 1.8 19.6088 3.75L18.776 8.325C21.7287 9.6 24 11.85 24 14.625V16.35C24 18.675 22.4858 20.625 20.2902 21.9C18.0946 23.25 15.2177 24 11.9621 24C8.70662 24 5.82965 23.25 3.63407 21.9C1.5142 20.625 0 18.675 0 16.35V14.625C0 11.85 2.19558 9.6 5.14826 8.325L4.31546 3.75ZM17.6404 8.925L18.6246 3.525C18.8517 2.175 17.8675 0.9 16.429 0.9C15.2177 0.9 14.2334 1.875 14.2334 3.075V8.025C13.9306 7.95 13.5521 7.95 13.2492 7.95C12.795 7.95 12.4164 7.875 11.9621 7.875C11.5079 7.875 11.0536 7.875 10.5994 7.95C10.2965 7.95 9.91798 8.025 9.61514 8.025V3.15C9.61514 1.95 8.63091 0.975 7.41956 0.975C6.05678 0.975 4.99685 2.25 5.29968 3.6L6.28391 9C3.1041 10.2 0.984227 12.3 0.984227 14.7V16.425C0.984227 20.1 5.90536 23.1 11.9621 23.1C18.0189 23.1 22.9401 20.1 22.9401 16.425V14.7C23.0158 12.225 20.8959 10.125 17.6404 8.925Z"
          fill={color ?? '#633001'}
        />
        <path
          d="M22.9244 16.6429C22.9244 20.1429 18.0103 23 11.9622 23C5.91409 23 1 20.1429 1 16.6429V15H23V16.6429H22.9244Z"
          fill={color ?? '#FEDC90'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.24828 3.70221C4.94483 2.3125 6.0069 1 7.37241 1C8.58621 1 9.57241 2.00368 9.57241 3.23897V8.33456C10.331 8.25735 11.0897 8.18015 11.9241 8.18015C12.6828 8.18015 13.4414 8.25735 14.2 8.33456V3.23897C14.2 2.00368 15.1862 1 16.4 1C17.7655 1 18.8276 2.3125 18.6 3.70221L17.6138 9.26103C20.8 10.4963 23 12.6581 23 15.1287C23 18.9118 18.069 22 12 22C5.93103 22 1 18.9118 1 15.1287C1 12.6581 3.12414 10.4963 6.31034 9.26103L5.24828 3.70221Z"
          fill={color ?? '#D1884F'}
        />
        <path
          d="M9 14C9 15.0833 8.34375 16 7.5 16C6.65625 16 6 15.0833 6 14C6 12.9167 6.65625 12 7.5 12C8.34375 12 9 12.9167 9 14Z"
          fill={color ?? '#633001'}
        />
        <path
          d="M17 14C17 15.0833 16.5625 16 16 16C15.4375 16 15 15.0833 15 14C15 12.9167 15.4375 12 16 12C16.5625 12 17 12.9167 17 14Z"
          fill={color ?? '#633001'}
        />
      </g>
      <defs>
        <clipPath id="clip0_64_318">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
