import { AssetProps } from './index'

export default function BaseScannerLogo({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_959_1101)">
        <path
          d="M4.90051 11.5584C4.90054 11.4224 4.92656 11.2878 4.97709 11.1622C5.02762 11.0366 5.10166 10.9226 5.19495 10.8267C5.28825 10.7308 5.39897 10.6548 5.52075 10.6033C5.64253 10.5517 5.77298 10.5254 5.9046 10.5261L7.56927 10.5317C7.83469 10.5317 8.08925 10.6406 8.27693 10.8345C8.46461 11.0284 8.57005 11.2914 8.57005 11.5656V18.0691C8.75754 18.0117 8.99749 17.9505 9.26153 17.8863C9.44446 17.8418 9.60748 17.7348 9.72418 17.5826C9.84087 17.4304 9.90439 17.242 9.90443 17.0478V8.98128C9.90443 8.70704 10.0099 8.44404 10.1975 8.2501C10.3852 8.05617 10.6398 7.94719 10.9052 7.94714H12.5749C12.8404 7.94719 13.0949 8.05617 13.2826 8.2501C13.4703 8.44404 13.5757 8.70704 13.5757 8.98128V16.4683C13.5757 16.4683 13.9934 16.2937 14.4001 16.1163C14.5512 16.0502 14.6801 15.9397 14.7708 15.7985C14.8615 15.6572 14.91 15.4915 14.9101 15.322V6.39633C14.9101 6.12215 15.0155 5.85919 15.2032 5.6653C15.3908 5.4714 15.6453 5.36245 15.9107 5.36239H17.5787C17.8439 5.36266 18.0982 5.47171 18.2857 5.66558C18.4732 5.85946 18.5785 6.12229 18.5785 6.39633V13.7463C20.0246 12.6636 21.4901 11.3613 22.6531 9.79542C22.8218 9.56813 22.9335 9.30122 22.9781 9.0185C23.0227 8.73579 22.9989 8.44606 22.9088 8.17516C22.3704 6.575 21.5147 5.10857 20.3963 3.86971C19.2779 2.63084 17.9214 1.64683 16.4138 0.980632C14.9062 0.314434 13.2805 -0.0192752 11.6409 0.000859803C10.0013 0.0209948 8.38376 0.39453 6.89189 1.09756C5.40001 1.80059 4.06661 2.81764 2.97702 4.08362C1.88744 5.3496 1.06565 6.83663 0.564261 8.44956C0.0628678 10.0625 -0.107092 11.7658 0.065256 13.4505C0.237604 15.1352 0.748465 16.7641 1.56515 18.2331C1.70743 18.4865 1.91677 18.6927 2.16855 18.8275C2.42034 18.9623 2.70413 19.02 2.98657 18.9939C3.30209 18.9652 3.69495 18.9246 4.16202 18.868C4.3653 18.8441 4.55297 18.7438 4.6893 18.5862C4.82562 18.4286 4.90108 18.2247 4.90129 18.0133V11.5584"
          fill={color ?? '#0a0b0d'}
        />
        <path
          d="M5 21.6723C6.78469 22.9926 8.89376 23.7851 11.0939 23.9621C13.294 24.1391 15.4994 23.6938 17.466 22.6753C19.4327 21.6568 21.0839 20.1049 22.2371 18.1913C23.3903 16.2777 24.0004 14.077 24 11.8327C24 11.5519 23.9872 11.2756 23.9688 11C19.5978 17.6294 11.5272 20.7286 5 21.6723"
          fill={color ?? '#c0d0db'}
        />
      </g>
      <defs>
        <clipPath id="clip0_959_1101">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
