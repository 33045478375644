import { AssetProps } from './index'

export default function Exit24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.699 11.9985L4.55534 18.1421L5.96955 19.5563L12.1492 13.3767L18.4972 19.4097L19.875 17.96L13.5639 11.962L20.1117 5.41421L18.6975 4L12.1137 10.5838L5.37779 4.18213L4 5.63185L10.699 11.9985Z"
        fill={color ?? '#FAFAFA'}
      />
    </svg>
  )
}
