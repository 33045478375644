import { AssetProps } from './index'

export default function EtherumLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.3663 0L12.2054 0.546885V16.4162L12.3663 16.5768L19.7327 12.2226L12.3663 0Z"
        fill={color ?? '#C3CDF3'}
      />
      <path
        d="M12.3664 0L5 12.2226L12.3664 16.5769V8.87439V0Z"
        fill={color ?? 'white'}
      />
      <path
        d="M12.3663 17.9716L12.2757 18.0821V23.7351L12.3663 24L19.7371 13.6195L12.3663 17.9716Z"
        fill={color ?? '#C3CDF3'}
      />
      <path
        d="M12.3664 23.9999V17.9715L5 13.6195L12.3664 23.9999Z"
        fill={color ?? 'white'}
      />
      <path
        d="M12.3663 16.5768L19.7326 12.2226L12.3663 8.87444V16.5768Z"
        fill={color ?? '#8499E8'}
      />
      <path
        d="M5.00006 12.2226L12.3663 16.5768V8.87444L5.00006 12.2226Z"
        fill={color ?? '#C3CDF3'}
      />
    </svg>
  )
}
