import { AssetProps } from './index'

export default function LinkedinLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_631_716)">
        <rect x="2" y="3" width="19" height="18" fill={color ?? '#FAFAFA'} />
        <path
          d="M20.4481 20.4487H16.8921V14.8797C16.8921 13.5517 16.8684 11.8421 15.0426 11.8421C13.1905 11.8421 12.9071 13.289 12.9071 14.783V20.4483H9.35103V8.99612H12.7648V10.5612H12.8126C13.5087 9.37097 14.803 8.66011 16.1809 8.71126C19.7851 8.71126 20.4496 11.082 20.4496 14.1662L20.4481 20.4487ZM5.33862 7.43068C4.19892 7.43088 3.27484 6.50711 3.27463 5.36738C3.27443 4.22765 4.19818 3.30355 5.33788 3.30335C6.47759 3.30314 7.40167 4.22691 7.40187 5.36664C7.40197 5.91396 7.18465 6.4389 6.79771 6.82598C6.41078 7.21306 5.88593 7.43058 5.33862 7.43068ZM7.11665 20.4487H3.55689V8.99612H7.11665V20.4487ZM22.221 0.000965599H1.76998C0.803424 -0.00994222 0.0107892 0.764307 -0.00100708 1.73087V22.2671C0.0103853 23.2342 0.802956 24.0092 1.76998 23.999H22.221C23.1899 24.011 23.9856 23.2361 23.999 22.2671V1.72939C23.9852 0.760915 23.1894 -0.0132891 22.221 -0.000671924"
          fill={color ?? '#0A66C2'}
        />
      </g>
      <defs>
        <clipPath id="clip0_631_716">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
