import { AssetProps } from './index'

export default function ChevronUp16({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.7425L13.8631 12L8 5.51507L2.13694 12L0.999999 10.7425L8 3L15 10.7425Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
