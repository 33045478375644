import clsx from 'clsx'
import { HTMLAttributes, ReactElement } from 'react'

import { H1 } from '../Typo/Header'
import { TextL } from '../Typo/Text'

export interface HeroProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  description: string | ReactElement
  leftContent?: ReactElement
  rightContent?: ReactElement
  isCentered?: boolean
}

export function Hero({
  title,
  description,
  rightContent,
  leftContent,
  className,
  isCentered = false,
}: HeroProps) {
  return (
    <div
      className={clsx(
        'grid items-center gap-8 md:gap-12 lg:grid-cols-2',
        className
      )}
    >
      <div className="flex flex-col gap-4">
        <H1 isBold className={clsx(isCentered && 'text-center')}>
          {title}
        </H1>

        {typeof description === 'string' ? (
          <TextL
            className={clsx(
              'max-w-[625px] break-words',
              isCentered && 'text-center'
            )}
          >
            {description}
          </TextL>
        ) : (
          description
        )}

        <div className="mt-4">{leftContent}</div>
      </div>

      <div className="w-full justify-self-end">{rightContent}</div>
    </div>
  )
}
