import clsx from 'clsx'
import { FC, HTMLAttributes, JSX } from 'react'

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  isBold?: boolean
  tag?: keyof JSX.IntrinsicElements
}

interface TextTemplateProps extends TextProps {
  isNumeric?: boolean
}

const setFontProps = (isBold?: boolean, isNumeric?: boolean) =>
  (isBold && isNumeric && 'font-bold font-roboto-mono') ||
  (isBold && 'font-semibold') ||
  (isNumeric && 'font-normal font-roboto-mono') ||
  'font-normal'

const TextTemplate: FC<TextTemplateProps> = ({
  children,
  isBold,
  tag: Tag = 'p',
  className,
  isNumeric,
}) => {
  return (
    <Tag className={clsx(className, setFontProps(isBold, isNumeric))}>
      {children}
    </Tag>
  )
}

export const TextL: FC<TextProps> = ({
  children,
  isBold,
  className,
  tag = 'p',
}) => {
  return (
    <TextTemplate
      isBold={isBold}
      tag={tag}
      className={clsx('text-base sm:text-lg', className)}
    >
      {children}
    </TextTemplate>
  )
}

export const Text: FC<TextProps> = ({
  children,
  isBold,
  className,
  tag = 'p',
}) => {
  return (
    <TextTemplate
      isBold={isBold}
      className={clsx('text-base', className)}
      tag={tag}
    >
      {children}
    </TextTemplate>
  )
}

export const TextNumeric: FC<TextProps> = ({
  children,
  isBold,
  className,
  tag = 'p',
}) => {
  return (
    <TextTemplate
      tag={tag}
      isBold={isBold}
      isNumeric
      className={clsx('text-base', className)}
    >
      {children}
    </TextTemplate>
  )
}

export const TextXs: FC<TextProps> = ({ children, isBold, className, tag }) => {
  return (
    <TextTemplate
      isBold={isBold}
      className={clsx('text-xs', className)}
      tag={tag}
    >
      {children}
    </TextTemplate>
  )
}

export const TextS: FC<TextProps> = ({ children, isBold, className, tag }) => {
  return (
    <TextTemplate
      isBold={isBold}
      className={clsx('text-sm', className)}
      tag={tag}
    >
      {children}
    </TextTemplate>
  )
}

export const TextSNumeric: FC<TextProps> = ({
  children,
  isBold,
  className,
  tag,
}) => {
  return (
    <TextTemplate
      isBold={isBold}
      tag={tag}
      isNumeric
      className={clsx('text-sm', className)}
    >
      {children}
    </TextTemplate>
  )
}

export type Texts =
  | typeof TextL
  | typeof Text
  | typeof TextS
  | typeof TextXs
  | typeof TextNumeric
  | typeof TextSNumeric
