import { AssetProps } from './index'

export default function Success({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '25'}
      height={size ?? '24'}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2072 6.70712L10.2072 18.7071C9.81666 19.0976 9.18349 19.0976 8.79297 18.7071L2.79297 12.7071L4.20718 11.2929L9.50008 16.5858L20.793 5.29291L22.2072 6.70712Z"
        fill={color ?? '#67DB8A'}
      />
    </svg>
  )
}
