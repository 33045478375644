import clsx from 'clsx'
import { HTMLAttributes, ReactElement } from 'react'

import { TextL, TextS } from '../Typo/Text'
import { Button, ButtonVariant } from '../Button'

export interface Element {
  name: string
  to: string
  icon?: ReactElement
  reloadDocument?: boolean
}

export interface ListItem {
  title: string
  elements: Element[]
}

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  lists: ListItem[]
  icon: ReactElement
  className?: string
}

export function Footer({ lists, icon, className, children }: FooterProps) {
  return (
    <footer className="bg-neutral-800 text-neutral-100">
      <div
        className={clsx(
          'container my-0 flex flex-col justify-between gap-8 py-12 lg:flex-row',
          className
        )}
      >
        <div className="flex flex-col gap-4">
          {icon}

          <TextS>
            tenset.io {new Date().getFullYear()} &copy; All rights reserved
          </TextS>

          <div>{children}</div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {lists.map(({ title, elements }) => (
            <List title={title} elements={elements} key={title} />
          ))}
        </div>
      </div>
    </footer>
  )
}

interface ListProps {
  title: string
  elements: Element[]
}

function List({ title, elements }: ListProps) {
  return (
    <div className="flex flex-col gap-5 2xl:w-60">
      <header>
        <TextL className="text-white" isBold>
          {title}
        </TextL>
      </header>

      <main className="flex flex-col items-start gap-6">
        {elements.map(({ name, to, icon, reloadDocument }) => (
          <div className="w-fit" key={name}>
            <Button
              reloadDocument={reloadDocument}
              variant={ButtonVariant.Link}
              to={to}
            >
              {icon}

              {name}
            </Button>
          </div>
        ))}
      </main>
    </div>
  )
}
