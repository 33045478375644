import { useEffect, useRef } from 'react'

export function useOutsideClick(handler: () => void) {
  const reference = useRef<HTMLElement>(null)

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (
        reference.current &&
        !reference.current.contains(event.target as Node)
      ) {
        handler()
      }
    }

    document.addEventListener('click', handleClick, true)
    return () => document.removeEventListener('click', handleClick, true)
  }, [handler])

  return { ref: reference }
}
