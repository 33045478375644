import { useState } from 'react'

import { UnicodeChars, addressShortener, copyToClipboard } from '../../utils'
import { Button, ButtonProps, ButtonVariant } from '../Button'
import { Icon, IconName } from '../Icon'

export interface ExplorerButtonProps extends ButtonProps {
  address?: string | null
  fullAddress?: boolean
  tokenStandard?: string
  explorerUrl?: string
  explorerType?: ExplorerType
  handleAddToMetamask?: () => void
  copyIconFilled?: boolean
}

export enum ExplorerType {
  ADDRESS = 'address',
  TOKEN = 'token',
  TRANSACTION = 'tx',
}

const { ADDRESS, TOKEN } = ExplorerType

export function ExplorerButton({
  address,
  fullAddress = false,
  tokenStandard,
  explorerUrl,
  explorerType = ADDRESS,
  handleAddToMetamask,
  copyIconFilled = false,
  ...props
}: ExplorerButtonProps) {
  const [hasCopied, setHasCopied] = useState(false)

  const { Success, Duplicate16, DuplicateFilled, MetaMask } = IconName

  const copyIcon = copyIconFilled ? DuplicateFilled : Duplicate16

  if (!address)
    return (
      <Button variant={ButtonVariant.Secondary} disabled {...props}>
        {UnicodeChars.enDash}
      </Button>
    )

  const EXPLORER_URL =
    explorerUrl && explorerType
      ? `${explorerUrl}/${explorerType}/${address}`
      : undefined

  function handleCopy() {
    if (!address) return

    copyToClipboard(address)

    setHasCopied(true)
    setTimeout(() => setHasCopied(false), 1000)
  }

  const showMetaMaskButton =
    explorerType === TOKEN && typeof handleAddToMetamask === 'function'

  return (
    <Button
      variant={ButtonVariant.Secondary}
      to={EXPLORER_URL}
      className="group/parent"
      {...props}
    >
      <span className="break-all group-hover/parent:underline">
        {tokenStandard && `${tokenStandard}:`}{' '}
        {fullAddress ? address : addressShortener(address)}
      </span>

      <button
        type="button"
        className="p-1 group/button-copy"
        onClick={event => {
          event.preventDefault()

          handleCopy()
        }}
      >
        <Icon
          name={hasCopied ? Success : copyIcon}
          color="white"
          size={16}
          className="group-hover/button-copy:scale-110"
        />
      </button>

      {showMetaMaskButton && (
        <button
          type="button"
          className="p-1 group/button-metamask"
          onClick={event => {
            event.preventDefault()

            handleAddToMetamask()
          }}
        >
          <Icon
            name={MetaMask}
            size={16}
            className="grayscale group-hover/button-metamask:scale-110 group-hover/button-metamask:grayscale-0"
          />
        </button>
      )}
    </Button>
  )
}
