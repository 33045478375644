import React, { ReactElement } from 'react'

export interface ProgressCircleProps {
  numerator: number
  denominator: number
  children?: ReactElement
}

export function ProgressCircle({
  numerator,
  denominator,
  children,
}: ProgressCircleProps) {
  const STROKE_WIDTH = 1

  const radius = 50 - STROKE_WIDTH / 2 // subtract half of the stroke width for proper fitting
  const circumference = 2 * Math.PI * radius
  const progress = (numerator / denominator) * circumference

  return (
    <div className="relative w-full h-full">
      <div className="absolute flex items-center justify-center flex-col gap-2 w-full h-full">
        {children}
      </div>

      <svg
        className="w-full h-full pointer-events-none"
        viewBox="0 0 100 100"
        transform="scale(-1,1)"
      >
        <circle
          className="text-neutral-600 stroke-neutral-700"
          strokeWidth={STROKE_WIDTH}
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
        ></circle>

        {progress > 0 && (
          <circle
            className="stroke-pink-500 -rotate-90 origin-[50%_50%]"
            strokeWidth={STROKE_WIDTH}
            strokeLinecap="round"
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            strokeDasharray={`${progress} ${circumference}`}
          ></circle>
        )}
      </svg>
    </div>
  )
}
