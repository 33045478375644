import { ReactElement } from 'react'

import { Tag, TagColor } from '../Tag'
import { H3 } from '../Typo/Header'
import { TextS } from '../Typo/Text'

interface Step {
  title: string
  timeline?: string
  content: ReactElement
}

export interface StepperProps {
  steps: Step[]
}

export function Stepper({ steps }: StepperProps) {
  return (
    <div className="flex flex-col md:grid md:grid-cols-3 gap-8">
      {steps.map(({ title, timeline, content }, index) => {
        const isLast = index === steps.length - 1
        return (
          <div key={index} className="shrink grow">
            <div className="mb-4 flex w-full items-center md:mb-6">
              <div
                style={{
                  filter: 'drop-shadow(0px 0px 25px rgba(118, 57, 227, 0.64))',
                }}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-neutral-900"
              >
                <TextS isBold>{index + 1}</TextS>
              </div>
              {!isLast && (
                <div className="relative hidden grow justify-center before:absolute before:top-1/2 before:block before:w-full before:border-t before:border-neutral-400 md:flex">
                  <div className="relative">
                    <Tag color={TagColor.NEUTRAL}>{timeline}</Tag>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start gap-2 md:pr-12">
              <H3 isBold>{title}</H3>
              {content}
            </div>
          </div>
        )
      })}
    </div>
  )
}
