import { AssetProps } from './index'

export default function ChevronDown24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29289 8.70711L4.70711 7.29289L12 14.5858L19.2929 7.29289L20.7071 8.70711L12 17.4142L3.29289 8.70711Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
