import { AssetProps } from './index'

export default function InfoAlertIcon({ className, color }: AssetProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 24C5.37931 24 0 18.6207 0 12C0 5.37931 5.37931 0 12 0C18.6207 0 24 5.37931 24 12C24 18.6207 18.6207 24 12 24ZM12 1.65517C6.28966 1.65517 1.65517 6.28966 1.65517 12C1.65517 17.7103 6.28966 22.3448 12 22.3448C17.7103 22.3448 22.3448 17.7103 22.3448 12C22.3448 6.28966 17.7103 1.65517 12 1.65517Z"
        fill={color ?? '#1A68FF'}
      />
      <path
        d="M12 18.0414C11.5366 18.0414 11.1724 17.6772 11.1724 17.2138V10.7421C11.1724 10.2786 11.5366 9.91449 12 9.91449C12.4634 9.91449 12.8276 10.2786 12.8276 10.7421V17.2138C12.8276 17.6772 12.4634 18.0414 12 18.0414Z"
        fill={color ?? '#1A68FF'}
      />
      <path
        d="M12 8.07724C11.8841 8.07724 11.7848 8.07724 11.6855 8.01104C11.5862 7.97793 11.4869 7.91173 11.4041 7.82897C11.2552 7.68 11.1724 7.46483 11.1724 7.24966C11.1724 7.13379 11.1724 7.03448 11.2386 6.93517C11.2717 6.83586 11.3379 6.73655 11.4041 6.65379C11.4869 6.58759 11.5862 6.52138 11.6855 6.48828C11.8841 6.40552 12.0993 6.40552 12.3145 6.48828C12.4138 6.52138 12.4966 6.58759 12.5793 6.65379C12.7283 6.81931 12.8276 7.03448 12.8276 7.24966C12.8276 7.46483 12.7448 7.68 12.5793 7.82897C12.4966 7.91173 12.4138 7.97793 12.3145 8.01104C12.2152 8.06069 12.0993 8.07724 12 8.07724Z"
        fill={color ?? '#1A68FF'}
      />
    </svg>
  )
}
