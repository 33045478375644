import { AssetProps } from './index'

export default function TelegramMonoLogo({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1072_8427)">
        <path
          d="M24 12C24 18.63 18.63 24 12 24C5.37 24 0 18.63 0 12C0 5.37 5.37 0 12 0C18.63 0 24 5.37 24 12Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M9.8002 17.4998C9.4102 17.4998 9.4802 17.3498 9.3402 16.9798L8.2002 13.2198L15.2802 8.7998L16.1102 9.0198L15.4202 10.8998L9.8002 17.4998Z"
          fill={color ?? '#15121A'}
        />
        <path
          d="M9.7998 17.5002C10.0998 17.5002 10.2298 17.3602 10.3998 17.2002C10.6598 16.9502 13.9998 13.7002 13.9998 13.7002L11.9498 13.2002L10.0498 14.4002L9.7998 17.4002V17.5002Z"
          fill={color ?? '#15121A'}
        />
        <path
          d="M9.99994 14.44L14.8399 18.01C15.3899 18.31 15.7899 18.16 15.9299 17.5L17.8999 8.22004C18.0999 7.41004 17.5899 7.05004 17.0599 7.29004L5.49994 11.75C4.70994 12.07 4.71994 12.51 5.35994 12.7L8.32994 13.63L15.1999 9.30004C15.5199 9.10004 15.8199 9.21004 15.5799 9.43004L9.99994 14.44Z"
          fill={color ?? '#15121A'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1072_8427">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
