import {
  AccordionItem,
  AccordionItemProps,
  AccordionTrigger,
  AccordionContent,
} from '@radix-ui/react-accordion'
import clsx from 'clsx'
import * as React from 'react'

import { Button, ButtonJustify, ButtonProps, ButtonVariant } from '../Button'
import { Icon, IconName } from '../Icon'

export interface DropdownProps
  extends Pick<AccordionItemProps, 'children' | 'className'>,
    Pick<ButtonProps, 'variant' | 'justify'> {
  buttonLabel: string
  fullWidthButton?: boolean
  usePanelMaxWidth?: boolean
}

export function Dropdown({
  buttonLabel,
  fullWidthButton = false,
  usePanelMaxWidth = true,
  variant = ButtonVariant.Ghost,
  justify = ButtonJustify.Center,
  children,
  className,
}: DropdownProps) {
  return (
    <AccordionItem value={buttonLabel} className={clsx('group', className)}>
      <DropdownElements
        variant={variant}
        fullWidthButton={fullWidthButton}
        usePanelMaxWidth={usePanelMaxWidth}
        justify={justify}
        buttonLabel={buttonLabel}
      >
        {children}
      </DropdownElements>
    </AccordionItem>
  )
}

function DropdownElements({
  variant,
  fullWidthButton,
  justify,
  buttonLabel,
  children,
  usePanelMaxWidth,
}: DropdownProps) {
  return (
    <>
      <AccordionTrigger
        className="transition-all [&[data-state=open]>svg]:rotate-180"
        asChild
      >
        <Button
          variant={variant}
          justify={fullWidthButton ? ButtonJustify.Start : justify}
          className={clsx(fullWidthButton && 'w-full')}
        >
          <span>{buttonLabel}</span>

          <Icon
            name={IconName.ChevronDown16}
            className="h-4 w-4 shrink-0 transition-transform duration-200"
          />
        </Button>
      </AccordionTrigger>

      <AccordionContent
        className={clsx(
          'mt-2 rounded-lg bg-neutral-800 px-6 xl:absolute xl:mt-4 xl:px-2 xl:py-4',
          usePanelMaxWidth && 'xl:max-w-xs'
        )}
      >
        {children}
      </AccordionContent>
    </>
  )
}
