import { AssetProps } from './index'

export default function TensetLogoWithLabel({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '65'}
      height={size ? (size * 32) / 65 : '32'}
      viewBox="0 0 99 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_62_226)">
        <path
          d="M25.651 22.8061L27.2495 21.2223C27.8398 20.6376 27.8398 19.663 27.2495 19.0782L19.1829 11.1107C18.5927 10.5259 17.6089 10.5259 17.0187 11.1107L8.95202 19.1025C8.36178 19.6873 8.36178 20.6619 8.95202 21.2467L10.5506 22.8305V29.1411V35.7929L8.95202 37.3767C8.36178 37.9614 8.36178 38.9361 8.95202 39.5208L17.0187 47.5127C17.6089 48.0975 18.5927 48.0975 19.1829 47.5127L27.2495 39.5208C27.8398 38.9361 27.8398 37.9614 27.2495 37.3767L25.651 35.7929V29.1411V22.8061ZM21.1504 29.1411V35.1838C21.1504 35.5736 20.9782 35.9635 20.7077 36.2558L18.1254 38.8142L15.5431 36.2558C15.2479 35.9635 15.1004 35.598 15.1004 35.1838V29.1411V23.4152C15.1004 23.0254 15.2725 22.6355 15.5431 22.3432L18.1254 19.7848L20.7077 22.3432C21.0028 22.6355 21.1504 23.001 21.1504 23.4152V29.1411Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M35.7097 7.87006L28.3808 0.609141C27.7906 0.0243698 26.8069 0.0243698 26.2166 0.609141L25.2575 1.55939C24.9624 1.85178 24.5935 1.99797 24.1754 1.99797H18.1008H12.0262C11.6327 1.99797 11.2392 1.82741 10.9441 1.55939L9.98495 0.609141C9.39471 0.0243698 8.41097 0.0243698 7.82073 0.609141L0.491876 7.87006C-0.0983664 8.45483 -0.0983664 9.42945 0.491876 10.0142L3.24634 12.7432C3.83659 13.3279 4.82032 13.3279 5.41057 12.7432L11.313 6.89544C11.6081 6.60305 11.977 6.45686 12.3951 6.45686H18.1008H23.8065C24.2 6.45686 24.5935 6.62742 24.8886 6.89544L30.791 12.7432C31.3813 13.3279 32.365 13.3279 32.9552 12.7432L35.7097 10.0142C36.2999 9.42945 36.2999 8.45483 35.7097 7.87006Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M63.0084 28.3614L58.0651 18.4203H55.4828V39.0579H58.0651V24.1949L63.0084 34.1117V39.0579H65.5907V18.4203H63.0084V28.3614Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M37.3574 18.4203H33.8898V20.6376H37.3574V39.0579H39.9398V20.6376H43.432V18.4203H39.9398H37.3574Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M45.1782 18.4203V20.6376V27.6305V29.8477V36.8406V39.0579H47.7359H52.9251V36.8406H47.7359V29.8477H52.6792V27.6305H47.7359V20.6376H52.9251V18.4203H47.7359H45.1782Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M98.7673 18.4203H95.275H92.7173H89.225V20.6376H92.7173V39.0579H95.275V20.6376H98.7673V18.4203Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M80.5435 18.4203V20.6376V27.6305V29.8477V36.8406V39.0579H83.1259H88.3151V36.8406H83.1259V29.8477H88.0446V27.6305H83.1259V20.6376H88.3151V18.4203H83.1259H80.5435Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M75.0346 18.2497H70.8783C69.4273 18.2497 68.2468 19.4193 68.2468 20.8569V25.4376C68.2468 26.2903 68.6649 27.0944 69.3781 27.5817L75.0346 31.4315L75.0592 31.4558V36.6213C75.0592 36.6457 75.0346 36.67 75.01 36.67H70.8537C70.8291 36.67 70.8045 36.6457 70.8045 36.6213V31.3827H68.2222V36.6213C68.2222 38.0589 69.4027 39.2284 70.8537 39.2284H75.01C76.461 39.2284 77.6415 38.0589 77.6415 36.6213V31.4558C77.6415 30.603 77.2234 29.799 76.5102 29.3117L70.8537 25.4619L70.8291 25.4376V20.8569C70.8291 20.8325 70.8537 20.8081 70.8783 20.8081H75.0346C75.0592 20.8081 75.0838 20.8325 75.0838 20.8569V25.8518H77.6661V20.8569C77.6661 19.4193 76.4856 18.2497 75.0346 18.2497Z"
          fill={color ?? '#FF63D7'}
        />
      </g>
      <defs>
        <clipPath id="clip0_62_226">
          <rect width="98.8657" height="48" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
