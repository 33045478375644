import { AssetProps } from './index'

export default function LinkedinMonoLogo({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.4482 20.4485H16.8921V14.8795C16.8921 13.5515 16.8684 11.842 15.0426 11.842C13.1905 11.842 12.9071 13.2889 12.9071 14.7828V20.4482H9.35106V8.99596H12.7649V10.561H12.8127C13.5087 9.37082 14.8031 8.65996 16.1809 8.7111C19.7851 8.7111 20.4496 11.0819 20.4496 14.1661L20.4482 20.4485ZM5.33865 7.43053C4.19895 7.43073 3.27487 6.50696 3.27466 5.36723C3.27446 4.2275 4.19821 3.3034 5.33791 3.3032C6.47762 3.30299 7.4017 4.22676 7.4019 5.36649C7.402 5.91381 7.18468 6.43875 6.79775 6.82583C6.41081 7.21291 5.88596 7.43043 5.33865 7.43053ZM7.11668 20.4485H3.55693V8.99596H7.11668V20.4485ZM22.221 0.000813011H1.77001C0.803454 -0.0100948 0.0108197 0.764154 -0.000976562 1.73072V22.267C0.0104158 23.234 0.802986 24.009 1.77001 23.9989H22.221C23.1899 24.0109 23.9856 23.2359 23.999 22.267V1.72924C23.9852 0.760763 23.1895 -0.0134417 22.221 -0.000824511"
        fill={color ?? 'currentColor'}
      />
    </svg>
  )
}
