import { AssetProps } from './index'

export default function Clock({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '15'}
      height={size ?? '15'}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.00426 13.5124C10.5986 13.5124 13.5124 10.5986 13.5124 7.00426C13.5124 3.4099 10.5986 0.496094 7.00426 0.496094C3.4099 0.496094 0.496094 3.4099 0.496094 7.00426C0.496094 10.5986 3.4099 13.5124 7.00426 13.5124Z"
        stroke={color ?? '#A3A3A3'}
        strokeMiterlimit="10"
      />
      <path
        d="M7.00391 3.15039V7.2242L8.77254 9.0127"
        stroke={color ?? '#A3A3A3'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
