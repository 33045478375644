import { AssetProps } from './index'

export default function BitgetLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill={color ?? '#1EA2B4'}
      className={className}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M8.1,0.5C8.4,0.2,8.8,0,9.2,0l2.9,0c0.4,0,0.6,0.4,0.3,0.7L6.7,6.9h3.2l3.2,3.4H6.7l6.4,6.9H9.2
				c-0.4,0-0.9-0.2-1.2-0.5l-6.5-7C1,9.1,1,8.1,1.6,7.5L8.1,0.5z"
            />
            <path
              className="st0"
              d="M17,23.5c-0.3,0.3-0.7,0.5-1.2,0.5h-2.9c-0.4,0-0.6-0.4-0.3-0.7l5.8-6.2h-3.2l-3.2-3.4h6.4l-6.4-6.9h3.9
				c0.4,0,0.9,0.2,1.2,0.5l6.5,7c0.6,0.6,0.6,1.6,0,2.2L17,23.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
