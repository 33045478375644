import { animated, useSpring } from '@react-spring/web'
import { FC } from 'react'

import { NumberFormatter } from '../../Formatters'

export interface AnimatedNumberProps {
  value: number
  formatter?: FC<{ value: number }>
  duration?: number
}

export function AnimatedNumber({
  value,
  formatter = props => <NumberFormatter {...props} />,
  duration = 1000,
}: AnimatedNumberProps) {
  const { number } = useSpring({
    number: value,
    config: {
      duration,
    },
  })

  const AnimatedFormatter = animated(formatter)

  return <AnimatedFormatter value={number} />
}
