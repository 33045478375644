import { AssetProps } from './index'

export default function TensetLogoWithLabelMono({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '99'}
      height={size ? (size * 48) / 99 : '48'}
      viewBox="0 0 99 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1072_8407)">
        <path
          d="M25.6509 22.8059L27.2495 21.2221C27.8397 20.6374 27.8397 19.6627 27.2495 19.078L19.1828 11.1105C18.5926 10.5257 17.6089 10.5257 17.0186 11.1105L8.95196 19.1023C8.36172 19.6871 8.36172 20.6617 8.95196 21.2465L10.5505 22.8302V29.1409V35.7927L8.95196 37.3764C8.36172 37.9612 8.36172 38.9358 8.95196 39.5206L17.0186 47.5125C17.6089 48.0973 18.5926 48.0973 19.1828 47.5125L27.2495 39.5206C27.8397 38.9358 27.8397 37.9612 27.2495 37.3764L25.6509 35.7927V29.1409V22.8059ZM21.1503 29.1409V35.1835C21.1503 35.5734 20.9782 35.9632 20.7076 36.2556L18.1253 38.814L15.543 36.2556C15.2479 35.9632 15.1003 35.5978 15.1003 35.1835V29.1409V23.415C15.1003 23.0252 15.2725 22.6353 15.543 22.3429L18.1253 19.7846L20.7076 22.3429C21.0027 22.6353 21.1503 23.0008 21.1503 23.415V29.1409Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M35.7098 7.8699L28.381 0.608989C27.7907 0.0242172 26.807 0.0242172 26.2168 0.608989L25.2576 1.55924C24.9625 1.85163 24.5936 1.99782 24.1755 1.99782H18.1009H12.0263C11.6328 1.99782 11.2393 1.82726 10.9442 1.55924L9.98507 0.608989C9.39483 0.0242172 8.41109 0.0242172 7.82085 0.608989L0.491999 7.8699C-0.0982443 8.45467 -0.0982443 9.42929 0.491999 10.0141L3.24647 12.743C3.83671 13.3278 4.82045 13.3278 5.41069 12.743L11.3131 6.89528C11.6082 6.6029 11.9771 6.4567 12.3952 6.4567H18.1009H23.8066C24.2001 6.4567 24.5936 6.62726 24.8887 6.89528L30.7911 12.743C31.3814 13.3278 32.3651 13.3278 32.9554 12.743L35.7098 10.0141C36.3001 9.42929 36.3001 8.45467 35.7098 7.8699Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M63.0085 28.3615L58.0652 18.4204H55.4829V39.058H58.0652V24.195L63.0085 34.1118V39.058H65.5908V18.4204H63.0085V28.3615Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M37.3573 18.4204H33.8896V20.6377H37.3573V39.058H39.9396V20.6377H43.4319V18.4204H39.9396H37.3573Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M45.1782 18.4204V20.6377V27.6306V29.8478V36.8407V39.058H47.7359H52.9252V36.8407H47.7359V29.8478H52.6792V27.6306H47.7359V20.6377H52.9252V18.4204H47.7359H45.1782Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M98.7674 18.4204H95.2751H92.7174H89.2251V20.6377H92.7174V39.058H95.2751V20.6377H98.7674V18.4204Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M80.5435 18.4204V20.6377V27.6306V29.8478V36.8407V39.058H83.1258H88.315V36.8407H83.1258V29.8478H88.0445V27.6306H83.1258V20.6377H88.315V18.4204H83.1258H80.5435Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M75.0346 18.2495H70.8783C69.4273 18.2495 68.2468 19.4191 68.2468 20.8566V25.4373C68.2468 26.2901 68.6649 27.0942 69.3781 27.5815L75.0346 31.4312L75.0592 31.4556V36.6211C75.0592 36.6454 75.0346 36.6698 75.01 36.6698H70.8537C70.8291 36.6698 70.8045 36.6454 70.8045 36.6211V31.3825H68.2222V36.6211C68.2222 38.0586 69.4027 39.2282 70.8537 39.2282H75.01C76.461 39.2282 77.6415 38.0586 77.6415 36.6211V31.4556C77.6415 30.6028 77.2234 29.7987 76.5102 29.3114L70.8537 25.4617L70.8291 25.4373V20.8566C70.8291 20.8323 70.8537 20.8079 70.8783 20.8079H75.0346C75.0592 20.8079 75.0837 20.8323 75.0837 20.8566V25.8515H77.6661V20.8566C77.6661 19.4191 76.4856 18.2495 75.0346 18.2495Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1072_8407">
          <rect width="98.8657" height="48" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
