import { AssetProps } from '.'

export default function TelegramOutline({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '15.867'}
      height={size ?? '13.027'}
      viewBox="0 0 15.867 13.027"
      className={className}
    >
      <path
        id="Telegram_Icon"
        data-name="Telegram Icon"
        d="M15.867,1.158l-.02,0A2.694,2.694,0,0,0,15.83.86a3.064,3.064,0,0,0-.085-.38A.652.652,0,0,0,15.172,0a3,3,0,0,0-.8.088c-.537.157-1.07.338-1.591.543C11.323,1.2,9.865,1.78,8.417,2.38,7.055,2.945,5.7,3.533,4.354,4.129,3.219,4.63,2.095,5.155.968,5.675a6.35,6.35,0,0,0-.7.369.447.447,0,0,0,0,.815A3.16,3.16,0,0,0,1,7.193c.661.2,1.331.361,2,.542a2.437,2.437,0,0,0,1.893-.277c.84-.476,1.661-.984,2.483-1.491,1.232-.76,2.459-1.53,3.688-2.3.11-.069.22-.138.334-.2a.259.259,0,0,1,.331.082c.076.113.021.195-.064.27l-.08.074c-.567.518-1.136,1.034-1.7,1.558-.4.368-.78.747-1.169,1.121A11.266,11.266,0,0,0,7.129,8.309a.749.749,0,0,0,.148,1.163c.241.2.5.374.748.558,1.238.9,2.518,1.745,3.827,2.54a2.706,2.706,0,0,0,.957.438.884.884,0,0,0,1-.4,2.1,2.1,0,0,0,.244-.648c.312-1.584.626-3.167.908-4.756.25-1.41.465-2.827.683-4.243.092-.6.15-1.2.223-1.807"
        transform="translate(0 -0.001)"
        fill={color ?? '#fff'}
      />
    </svg>
  )
}
