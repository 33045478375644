import { AssetProps } from './index'

export default function InfoIcon({ size, color, className }: AssetProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '7.765'}
      height={size ? (size * 23.849) / 7.765 : '23.849'}
      viewBox="0 0 7.765 23.849"
      className={className}
    >
      <g
        id="Group_339"
        data-name="Group 339"
        transform="translate(123.901 -6.423)"
      >
        <g id="Group_340" data-name="Group 340" transform="translate(0 0)">
          <g
            id="Group_341"
            data-name="Group 341"
            transform="translate(-123.705 6.619)"
          >
            <path
              id="Path_1646"
              data-name="Path 1646"
              d="M5.85,25.954H1.912A1.413,1.413,0,0,1,.5,24.442L1.3,13.1A1.412,1.412,0,0,1,2.708,11.79H5.129a1.413,1.413,0,0,1,1.41,1.323L7.26,24.451A1.412,1.412,0,0,1,5.85,25.954Z"
              transform="translate(-0.195 -2.8)"
              fill="none"
              stroke={color ?? 'currentColor'}
              strokeWidth="1"
            />
            <path
              id="Path_1647"
              data-name="Path 1647"
              d="M6.949,3.442A2.942,2.942,0,1,1,4.008.5,2.942,2.942,0,0,1,6.949,3.442Z"
              transform="translate(-0.326 -0.196)"
              fill="none"
              stroke={color ?? 'currentColor'}
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
