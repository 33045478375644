import { AssetProps } from './index'

export default function ChevronUp({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '18'}
      height={size ? (size * 11) / 18 : '11'}
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1L9 9L17 1"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
    </svg>
  )
}
