import clsx from 'clsx'
import { HTMLAttributes } from 'react'

export enum TagColor {
  NEUTRAL = 'neutral',
  GREEN = 'green',
  YELLOW = 'yellow',
  VIOLET = 'violet',
  BLUE = 'blue',
}

export interface TagProps extends HTMLAttributes<HTMLSpanElement> {
  color?: TagColor
}

const { NEUTRAL, GREEN, BLUE, YELLOW, VIOLET } = TagColor

export function Tag({ children, color = GREEN }: TagProps) {
  return (
    <span
      className={clsx(
        'inline-flex max-w-fit items-center px-2 py-1 text-sm rounded text-center',
        color === NEUTRAL && 'bg-neutral-600 text-neutral-100',
        color === YELLOW && 'bg-[#423000] text-[#DBBA67]',
        color === GREEN && 'bg-green-900 text-green-100',
        color === BLUE && 'bg-blue-900 text-blue-0',
        color === VIOLET && 'bg-violet-900 text-violet-0'
      )}
    >
      {children}
    </span>
  )
}
