import { HTMLAttributes, ReactElement } from 'react'

import { H3 } from '../Typo/Header'
import { Text } from '../Typo/Text'
import { renderImage } from '../../utils'

export interface BigCardProps extends HTMLAttributes<HTMLDivElement> {
  cover: string | ReactElement
  title: string
  description: string
}

export function BigCard({ cover, title, description }: BigCardProps) {
  return (
    <div className="flex flex-col gap-12 rounded-[10px] bg-neutral-800 p-4 md:flex-row md:p-6">
      <div className="h-auto w-full overflow-hidden rounded-[10px] md:max-w-[50%]">
        {renderImage(cover)}
      </div>

      <div className="flex flex-col justify-center gap-2 md:w-[50%] md:pr-6">
        <H3 isBold>{title}</H3>

        <Text>{description}</Text>
      </div>
    </div>
  )
}
