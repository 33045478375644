import clsx from 'clsx'
import { HTMLAttributes } from 'react'

export function ListTableItem({
  className,
  children,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'flex flex-wrap items-center justify-around gap-4 bg-neutral-800 py-6 md:grid md:auto-cols-fr md:grid-flow-col md:place-items-center',
        className
      )}
    >
      {children}
    </div>
  )
}
