import { HTMLAttributes } from 'react'

export interface IFrameProps extends HTMLAttributes<HTMLIFrameElement> {
  src: string
}

export function IFrame({
  title = 'YouTube video player',
  ...props
}: IFrameProps) {
  return (
    <div className="relative aspect-video h-full w-full">
      <iframe
        title={title}
        className="relative min-h-full w-full"
        {...props}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  )
}
