import * as AccordionPrimitive from '@radix-ui/react-accordion'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { Divider } from '../Divider'
import { Icon, IconName } from '../Icon'
import { TextL } from '../Typo/Text'

const ExpansionPanel = AccordionPrimitive.Root

const ExpansionPanelItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={clsx(
      'flex flex-col transition-all [&[data-state=open]>div>svg]:rotate-180',
      className
    )}
    {...props}
  >
    <div className="flex items-center">
      <Icon
        name={IconName.ChevronDown24}
        className="h-6 w-6 shrink-0 transition-transform duration-200"
      />

      <div className="w-full pl-4">{children}</div>
    </div>

    <Divider />
  </AccordionPrimitive.Item>
))
ExpansionPanelItem.displayName = 'ExpansionPanelItem'

const ExpansionPanelTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={clsx('text-left py-4 md:py-8 w-full', className)}
      {...props}
    >
      <TextL className="w-full" isBold>
        {children}
      </TextL>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
ExpansionPanelTrigger.displayName = AccordionPrimitive.Trigger.displayName

const ExpansionPanelContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={clsx('pb-4 md:pb-8 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

ExpansionPanelContent.displayName = AccordionPrimitive.Content.displayName

export {
  ExpansionPanel,
  ExpansionPanelContent,
  ExpansionPanelItem,
  ExpansionPanelTrigger,
}
