import clsx from 'clsx'
import { HTMLAttributes, ReactElement } from 'react'

import { Divider, DividerColor } from '../Divider'

export interface ListTableProps extends HTMLAttributes<HTMLDivElement> {
  elements: ReactElement[]
  dividerColor?: DividerColor
  withHeaders?: boolean
}

export function ListTable({
  className,
  elements,
  dividerColor = DividerColor.NEUTRAL400,
  withHeaders = false,
}: ListTableProps) {
  return (
    <div className={clsx('!rounded-lg bg-neutral-800 px-5', className)}>
      {elements.map((element, index) => (
        <div key={index}>
          {element}

          {index !== elements.length - 1 && (
            <Divider
              color={dividerColor}
              className={clsx(withHeaders && index === 0 && 'hidden md:block')}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export * from './item'
