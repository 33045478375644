import { AssetProps } from './index'

export default function Done({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '14'}
      height={size ?? '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2 7.83108L4.80303 11L11 4"
        stroke={color ?? '#67DB8A'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
