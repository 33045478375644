import { AssetProps } from './index'

export default function TwitterMonoLogo({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ? (size * 20) / 24 : '20'}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24 2.30888C23.117 2.70049 22.168 2.96517 21.172 3.08421C22.1886 2.47479 22.9693 1.50984 23.3369 0.360101C22.3856 0.924306 21.3318 1.33409 20.2102 1.55497C19.312 0.598003 18.0323 0 16.6162 0C13.8968 0 11.6921 2.20459 11.6921 4.92378C11.6921 5.30969 11.7358 5.6855 11.8197 6.04587C7.72742 5.84054 4.09929 3.88026 1.67076 0.901306C1.24692 1.62853 1.0041 2.47436 1.0041 3.37671C1.0041 5.08496 1.87346 6.59208 3.19464 7.47504C2.38745 7.44949 1.62827 7.228 0.964337 6.85921C0.963986 6.87975 0.963986 6.90038 0.963986 6.9211C0.963986 9.30679 2.66125 11.2969 4.91377 11.7492C4.50056 11.8617 4.06558 11.9219 3.61646 11.9219C3.2992 11.9219 2.99072 11.8911 2.69014 11.8336C3.31667 13.7898 5.13508 15.2133 7.28972 15.2531C5.60456 16.5737 3.48144 17.3609 1.17459 17.3609C0.777175 17.3609 0.385208 17.3376 0 17.2921C2.17904 18.6892 4.76726 19.5043 7.5479 19.5043C16.6047 19.5043 21.5572 12.0014 21.5572 5.49474C21.5572 5.28125 21.5525 5.06889 21.543 4.85776C22.505 4.16355 23.3398 3.2963 24 2.30888Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  )
}
