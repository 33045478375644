import { AssetProps } from './index'

export default function ErrorAlertIcon({ className }: AssetProps) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9924 15.2142C11.5554 15.2142 11.2121 14.8702 11.2121 14.4324V7.16146C11.2121 6.72364 11.5554 6.37964 11.9924 6.37964C12.4293 6.37964 12.7726 6.72364 12.7726 7.16146V14.4324C12.7726 14.8702 12.4293 15.2142 11.9924 15.2142Z"
        fill="#D42A2A"
      />
      <path
        d="M11.9924 18.2633C11.8987 18.2633 11.7895 18.2633 11.6959 18.2007C11.6023 18.1695 11.5242 18.1069 11.4462 18.0444C11.3682 17.9662 11.3214 17.8724 11.2746 17.7785C11.2434 17.6847 11.2121 17.5909 11.2121 17.4815C11.2121 17.3876 11.2434 17.2782 11.2746 17.1844C11.3214 17.0905 11.3682 17.0124 11.4462 16.9342C11.6647 16.7153 12.008 16.6371 12.2889 16.7622C12.3981 16.8091 12.4761 16.856 12.5541 16.9342C12.6166 17.0124 12.679 17.0905 12.7258 17.1844C12.757 17.2782 12.7882 17.3876 12.7882 17.4815C12.7882 17.5909 12.757 17.6847 12.7258 17.7785C12.679 17.8724 12.6166 17.9662 12.5541 18.0444C12.4761 18.1069 12.3981 18.1695 12.3045 18.2007C12.1952 18.2476 12.1016 18.2633 11.9924 18.2633Z"
        fill="#D42A2A"
      />
      <path
        d="M21.433 21.5H2.56697C1.64629 21.5 0.819246 21.0153 0.351105 20.2178C-0.117035 19.4204 -0.117035 18.4665 0.351105 17.6535L9.79194 1.28218C10.2601 0.484727 11.0871 0 12.0078 0C12.9285 0 13.7555 0.484727 14.2237 1.28218L23.6489 17.6535C24.117 18.4509 24.117 19.4047 23.6489 20.2178C23.1808 21.0309 22.3537 21.5 21.433 21.5ZM11.9922 1.56364C11.8205 1.56364 11.3836 1.61055 11.1339 2.064L1.70871 18.4353C1.44343 18.8887 1.63069 19.2796 1.70871 19.436C1.78674 19.5924 2.05202 19.9364 2.56697 19.9364H21.433C21.948 19.9364 22.2133 19.5924 22.2913 19.436C22.3693 19.2796 22.5566 18.8887 22.2913 18.4353L12.8661 2.064C12.6008 1.61055 12.1795 1.56364 12.0078 1.56364H11.9922Z"
        fill="#D42A2A"
      />
    </svg>
  )
}
