import { ButtonVariant } from '../Button'
import { Icon, IconName } from '../Icon'
import { Modal, ModalContent, ModalTrigger } from '../Modal'
import { Video, VideoProps } from '../Video'

export interface VideoButtonProps extends VideoProps {
  buttonLabel?: string
  buttonVariant?: ButtonVariant
}

export function VideoButton({
  buttonLabel = 'Watch Video',
  buttonVariant = ButtonVariant.Secondary,
  ...videoProps
}: VideoButtonProps) {
  return (
    <Modal>
      <ModalTrigger variant={buttonVariant}>
        {buttonLabel} <Icon name={IconName.ChevronRight16} />
      </ModalTrigger>

      <ModalContent
        withPadding={false}
        withXCloseButton={false}
        alwaysCentered
        className="aspect-video"
      >
        <Video {...videoProps} autoPlay />
      </ModalContent>
    </Modal>
  )
}
