import clsx from 'clsx'
import { HTMLAttributes, ReactElement } from 'react'

import { H2, H3 } from '../Typo/Header'
import { renderImage } from '../../utils'

export interface CardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string | ReactElement
  image?: string | ReactElement
  action?: ReactElement
  isLarge?: boolean
  isCentered?: boolean
  limitWidth?: boolean
}

export function Card({
  title,
  image,
  children,
  action,
  isLarge,
  isCentered,
  limitWidth = true,
}: CardProps) {
  return (
    <div
      className={clsx(
        'flex flex-col gap-4 font-sora text-white md:gap-6',
        (isLarge || isCentered) && 'sm:items-center',
        limitWidth &&
          (isLarge ? 'sm:max-w-[800px] sm:items-center' : 'sm:max-w-[400px]')
      )}
    >
      {image && (
        <div className="h-[96px] w-[96px] overflow-hidden rounded-[10px] sm:h-[124px] sm:w-[124px]">
          {renderImage(image)}
        </div>
      )}

      <div
        className={clsx(
          'flex flex-col gap-2',
          isLarge ? 'mb-2 gap-4 sm:items-center' : 'gap-2'
        )}
      >
        {isLarge ? <H2 isBold>{title}</H2> : <H3 isBold>{title}</H3>}

        {children && <main>{children}</main>}
      </div>

      {action && <div>{action}</div>}
    </div>
  )
}
