import {
  LineChart,
  Line,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from 'recharts'

export interface GraphProps {
  data: Record<string, unknown>[]
  graphColor?: string
  highlightLatestY?: boolean
  highlightLatestYFormatter?: (value: number) => string
  xAxisProps?: XAxisProps
  yAxisProps?: YAxisProps
  tooltipProps?: TooltipProps<number, string>
}

export function Graph({
  data,
  graphColor = '#FD62D5',
  highlightLatestY = false,
  highlightLatestYFormatter,
  xAxisProps,
  yAxisProps,
  tooltipProps,
}: GraphProps) {
  if (!data || data.length === 0) return null

  const [xKey, yKey] = Object.keys(data[0])

  const latestY = data.at(-1)![yKey] as number

  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <XAxis
          dataKey={xKey}
          axisLine={false}
          tickLine={false}
          padding={{
            left: 20,
            right: 20,
          }}
          {...xAxisProps}
        />

        <YAxis
          width={75}
          axisLine={false}
          tickLine={false}
          padding={{ top: 20, bottom: 20 }}
          {...yAxisProps}
        />

        {highlightLatestY && (
          <ReferenceLine y={latestY} strokeWidth={0}>
            <Label
              value={latestY}
              position="right"
              fill={graphColor}
              fontSize={12}
              formatter={highlightLatestYFormatter}
            />
          </ReferenceLine>
        )}

        <Tooltip
          cursor={false}
          wrapperClassName="text-xs"
          contentStyle={{
            background: 'black',
            padding: '0px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
          {...tooltipProps}
        />

        <Line dataKey={yKey} stroke={graphColor} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export * from './graph-samples'
