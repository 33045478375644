import * as ModalPrimitive from '@radix-ui/react-dialog'
import clsx from 'clsx'
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useSwipeDown } from '../../hooks'
import { Button, ButtonProps, ButtonVariant } from '../Button'
import { Icon, IconName } from '../Icon'
import { ScrollArea } from '../ScrollArea'

export interface ModalProps extends ComponentProps<typeof ModalPrimitive.Root> {
  onClose?: () => void
}

const Modal = ({ onClose, open, ...props }: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleSwipedDown = useCallback(() => {
    setIsOpen(false)
  }, [])

  useSwipeDown(handleSwipedDown, 50, '.scrollable-content')

  return (
    <ModalPrimitive.Root
      modal={true}
      open={isOpen}
      onOpenChange={isOpen => {
        setIsOpen(isOpen)

        if (!isOpen && typeof onClose === 'function') onClose()
      }}
      {...props}
    />
  )
}
Modal.displayName = ModalPrimitive.Root.displayName

const ModalTrigger = forwardRef<
  ElementRef<typeof ModalPrimitive.Trigger>,
  ButtonProps
>(({ ...props }, ref) => (
  <ModalPrimitive.Trigger asChild>
    <Button ref={ref} {...props} />
  </ModalPrimitive.Trigger>
))
ModalTrigger.displayName = ModalPrimitive.Trigger.displayName

const ModalPortal = ModalPrimitive.Portal

const ModalClose = forwardRef<
  ElementRef<typeof ModalPrimitive.Close>,
  ButtonProps
>(({ ...props }, ref) => (
  <ModalPrimitive.Close asChild>
    <Button ref={ref} variant={ButtonVariant.Secondary} {...props} />
  </ModalPrimitive.Close>
))
ModalClose.displayName = ModalPrimitive.Close.displayName

const ModalOverlay = forwardRef<
  ElementRef<typeof ModalPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Overlay
    ref={ref}
    className={clsx(
      'fixed inset-0 z-[999] bg-neutral-900/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
))
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName

interface ModalContentProps
  extends ComponentPropsWithoutRef<typeof ModalPrimitive.Content> {
  alwaysCentered?: boolean
  withPadding?: boolean
  withXCloseButton?: boolean
}

const ModalContent = forwardRef<
  ElementRef<typeof ModalPrimitive.Content>,
  ModalContentProps
>(
  (
    {
      className,
      children,
      alwaysCentered = false,
      withPadding = true,
      withXCloseButton = true,
      ...props
    },
    ref
  ) => (
    <ModalPortal>
      <ModalOverlay />

      <ModalPrimitive.Content
        ref={ref}
        className={clsx(
          'fixed z-[999] h-min max-h-[75vh] flex flex-col gap-4 bg-neutral-800 shadow-lg duration-200 overflow-hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          alwaysCentered
            ? 'left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[90%] lg:w-[50vw] rounded-[10px]'
            : 'left-0 bottom-0 sm:left-[50%] sm:top-[50%] sm:translate-x-[-50%] sm:translate-y-[-50%] w-full sm:w-10/12 lg:w-2/3 max-w-screen-lg rounded-t-[20px] sm:rounded-[10px]',
          withPadding && 'p-6 md:p-12',
          className
        )}
        {...props}
      >
        {children}

        {withXCloseButton && (
          <ModalPrimitive.Close className="absolute right-0 top-0" asChild>
            <Button variant={ButtonVariant.Ghost} isIcon>
              <Icon name={IconName.Exit24} />

              <span className="sr-only">Close</span>
            </Button>
          </ModalPrimitive.Close>
        )}
      </ModalPrimitive.Content>
    </ModalPortal>
  )
)
ModalContent.displayName = ModalPrimitive.Content.displayName

const ModalMain = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <ScrollArea
    className="scrollable-content flex flex-col grow overflow-auto has-[div[data-state=visible]]:pr-8"
    type="always"
  >
    <div className={clsx(className || 'flex flex-col gap-4')} {...props} />
  </ScrollArea>
)
ModalMain.displayName = 'ModalMain'

const ModalHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx('flex flex-col space-y-1.5', className)} {...props} />
)
ModalHeader.displayName = 'ModalHeader'

const ModalFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={clsx(
      'flex flex-col gap-2 sm:flex-row sm:items-start',
      className
    )}
    {...props}
  />
)
ModalFooter.displayName = 'ModalFooter'

const ModalTitle = forwardRef<
  ElementRef<typeof ModalPrimitive.Title>,
  ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ ...props }, ref) => (
  <ModalPrimitive.Title
    ref={ref}
    className="text-lg sm:text-xl font-semibold sm:font-medium"
    {...props}
  />
))
ModalTitle.displayName = ModalPrimitive.Title.displayName

const ModalDescription = forwardRef<
  ElementRef<typeof ModalPrimitive.Description>,
  ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ ...props }, ref) => (
  <ModalPrimitive.Description ref={ref} className="text-base" {...props} />
))
ModalDescription.displayName = ModalPrimitive.Description.displayName

export {
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalOverlay,
  ModalPortal,
  ModalTitle,
  ModalTrigger,
}
