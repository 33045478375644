import clsx from 'clsx'
import { ReactElement, useState } from 'react'

import { getRowOrder } from '../../utils'
import { Text, TextS, TextSNumeric, Texts } from '../Typo/Text'

export interface TableTypography {
  header: Texts
  mainRow: Texts
  row: Texts
}

export type TableHeaders<T extends string = string> =
  | Record<T, string | { label: string; additionalContent: ReactElement }>
  | undefined
export type TableItem<T extends string = string> = Record<T, Cell>

type CellData = ReactElement | string | number
type Cell = CellData | ((isRowClicked?: boolean) => CellData)

export interface TableProps<T extends string> {
  headers?: TableHeaders<T>
  items: TableItem<T>[]
  typography?: TableTypography
  orderColumnLabel?: string
  isRaw?: boolean
}

export function Table<T extends string>({
  headers,
  items,
  typography = {
    header: TextS,
    mainRow: Text,
    row: TextSNumeric,
  },
  orderColumnLabel,
  isRaw = false,
}: TableProps<T>) {
  const headerLabels = headers
    ? (Object.values(headers) as (typeof headers)[keyof typeof headers][])
    : undefined

  const { header: HeaderTag } = typography

  return (
    <div
      className={clsx(
        'w-full rounded-lg text-left',
        !isRaw && 'md:bg-neutral-800 md:p-6'
      )}
    >
      {/*https://limebrains.com/blog/2021-03-02T13:00-heigh-100-inside-table-td/*/}
      <table className="w-full">
        {headerLabels && (
          <thead
            className={clsx('sr-only', !isRaw && 'capitalize md:not-sr-only')}
          >
            <tr>
              {orderColumnLabel !== undefined && (
                <th scope="col" className="md:pb-6 px-2">
                  <HeaderTag isBold>{orderColumnLabel}</HeaderTag>
                </th>
              )}

              {headerLabels.map(header => {
                if (typeof header === 'string') {
                  return (
                    <th scope="col" key={header} className="md:pb-6 px-2">
                      <HeaderTag isBold>{header}</HeaderTag>
                    </th>
                  )
                }

                const { label, additionalContent } = header

                return (
                  <th
                    scope="col"
                    key={label}
                    className="md:pb-6 px-2 flex items-center"
                  >
                    <HeaderTag isBold>{label}</HeaderTag>

                    {additionalContent}
                  </th>
                )
              })}
            </tr>
          </thead>
        )}

        <tbody className="flex flex-col gap-4 md:table-row-group">
          {items.map((item, index) => (
            <TableRow<T>
              headers={headers}
              item={item}
              typography={typography}
              rowIndex={index}
              orderRow={orderColumnLabel !== undefined}
              isRaw={isRaw}
              key={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface TableRowProps<T extends string> {
  headers: TableHeaders<T>
  item: TableItem<T>
  typography: TableTypography
  rowIndex: number
  orderRow: boolean
  isRaw: boolean
}

function TableRow<T extends string>({
  headers,
  item,
  typography,
  rowIndex,
  orderRow,
  isRaw,
}: TableRowProps<T>) {
  const { mainRow: MainRowTag, row: RowTag } = typography

  const [isRowClicked, setIsRowClicked] = useState(false)

  return (
    <tr
      onClick={() => setIsRowClicked(!isRowClicked)}
      className={clsx(
        'md:table-row md:rounded-none',
        rowIndex !== 0 &&
          (isRaw
            ? 'border-t border-neutral-400'
            : 'md:border-t md:border-neutral-400'),
        isRaw
          ? 'flex flex-col items-start pt-4 sm:flex-row sm:flex-wrap sm:items-center sm:justify-between'
          : 'block rounded-xl bg-neutral-800 p-4 pt-6'
      )}
    >
      {orderRow && (
        <td className="float-right block pb-4 md:float-none md:table-cell md:py-6">
          <RowTag>{getRowOrder(rowIndex)}</RowTag>
        </td>
      )}

      {Object.entries(item).map(([headerName, value], itemIndex) => {
        const _value = typeof value === 'function' ? value(isRowClicked) : value
        const headerContent = headers?.[headerName as T]
        const headerLabel =
          typeof headerContent === 'string'
            ? headerContent
            : headerContent?.label

        // Row main
        if (itemIndex === 0) {
          return (
            <th
              scope="row"
              key={headerName}
              className={clsx(
                'md:py-6 px-2',
                !isRaw && 'block pb-3 md:table-cell'
              )}
            >
              <MainRowTag tag="div" isBold>
                {_value}
              </MainRowTag>
            </th>
          )
        }

        // Row data
        return (
          <td
            key={headerName}
            className={clsx(
              'h-full py-4 text-right md:py-6 px-2',
              !isRaw &&
                'flex flex-wrap justify-between gap-2 md:table-cell md:text-left',
              itemIndex !== 1 &&
                !isRaw &&
                'border-t border-neutral-400 md:border-none'
            )}
          >
            {headerName !== 'priceGraph' && (
              <span className="md:hidden">{headerLabel}</span>
            )}
            <RowTag tag="div" className="h-full">
              {_value}
            </RowTag>
          </td>
        )
      })}
    </tr>
  )
}
