import {
  useButtonProps,
  ButtonProps as BaseButtonProps,
} from '@restart/ui/Button'
import { forwardRef, Ref, JSX } from 'react'
import clsx from 'clsx'
import { Link, LinkProps, NavLink } from '@remix-run/react'

import { Icon, IconName } from '../Icon'
import { isExternalLink } from '../../utils'

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryDarker = 'secondary-lighter',
  Ghost = 'ghost',
  Link = 'link',
}

export enum ButtonJustify {
  Start = 'justify-start',
  Center = 'justify-center',
  Between = 'justify-between',
}

type IntrinsicElements = keyof JSX.IntrinsicElements

export interface ButtonProps
  extends Omit<BaseButtonProps, 'as' | 'href' | 'target' | 'rel'>,
    Partial<Pick<LinkProps, 'to' | 'reloadDocument'>> {
  as?: IntrinsicElements | typeof Link | typeof NavLink
  variant?: ButtonVariant
  reverseChildren?: boolean
  justify?: ButtonJustify
  usePadding?: boolean
  useSelectedState?: boolean
  loading?: boolean
  isIcon?: boolean
}

const buttonVariantsFactory = (isDisabled: boolean) => ({
  [ButtonVariant.Primary]: isDisabled
    ? 'bg-neutral-500 border border-white/0'
    : 'bg-blue-500 md:hover:bg-blue-600 active:bg-blue-700 border border-white/0',
  [ButtonVariant.Secondary]: isDisabled
    ? 'border-neutral-100 bg-transparent border'
    : 'md:hover:bg-neutral-700 active:bg-neutral-600 border border-neutral-300',
  [ButtonVariant.SecondaryDarker]: isDisabled
    ? 'border-neutral-100 bg-transparent border'
    : 'md:hover:bg-neutral-900 active:bg-neutral-800 border border-neutral-600',
  [ButtonVariant.Ghost]:
    !isDisabled &&
    'md:hover:bg-neutral-700 active:bg-neutral-600 border border-white/0',
  [ButtonVariant.Link]:
    'text-neutral-100 md:hover:text-white active:text-white border border-white/0',
})

const buttonPaddingFactory = (isIcon?: boolean) =>
  isIcon ? 'p-3' : 'px-5 py-3'

const buttonSelectedStates = {
  [ButtonVariant.Primary]: 'group-focus-within:bg-blue-800',
  [ButtonVariant.Secondary]: 'group-focus-within:bg-neutral-600',
  [ButtonVariant.SecondaryDarker]: 'group-focus-within:bg-neutral-800',
  [ButtonVariant.Ghost]: 'group-focus-within:bg-neutral-600',
  [ButtonVariant.Link]: 'group-focus-within:text-white',
}

function ButtonComponent(
  {
    as,
    variant = ButtonVariant.Primary,
    reverseChildren,
    justify = ButtonJustify.Center,
    usePadding = true,
    useSelectedState = false,
    disabled,
    className,
    children,
    loading,
    isIcon,
    to,
    reloadDocument,
    ...props
  }: ButtonProps,
  ref: Ref<HTMLElement>
) {
  const externalLink = isExternalLink(to)
  const [buttonProps, { tagName: Component }] = useButtonProps({
    /* prettier-ignore */
    tagName: (as ??
      (to ? ((externalLink && !reloadDocument) ? 'a' : Link) : 'button')) as IntrinsicElements,
    ...props,
  })

  const isButtonVariantLink = variant === ButtonVariant.Link
  const buttonVariants = buttonVariantsFactory(!!disabled)

  return (
    <Component
      ref={ref}
      {...(externalLink ? {} : buttonProps)}
      {...(externalLink
        ? { href: to, target: '_blank', rel: 'noreferrer' }
        : { to })}
      {...props}
      className={clsx(
        'inline-flex items-center gap-2 text-sm font-semibold leading-6 transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-100 disabled:cursor-not-allowed disabled:text-white',
        disabled && '!text-neutral-100',
        !isButtonVariantLink && 'rounded text-white',
        usePadding && !isButtonVariantLink && buttonPaddingFactory(isIcon),
        reverseChildren && 'flex-row-reverse',
        justify,
        buttonVariants[variant],
        useSelectedState && !disabled && buttonSelectedStates[variant],
        className
      )}
      disabled={disabled}
      {...(reloadDocument ? { reloadDocument } : {})}
    >
      {loading && <Icon name={IconName.Loading2} />}
      {children}
    </Component>
  )
}

export const Button = forwardRef(ButtonComponent)
