import { AssetProps } from './index'

export default function BitcoinLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_486_568)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.09956 23.6387C15.5296 25.242 22.0414 21.3292 23.6444 14.8999C25.2473 8.47024 21.3349 1.9573 14.9052 0.354376C8.47699 -1.24854 1.96517 2.66462 0.363001 9.09468C-1.24067 15.5236 2.67212 22.0358 9.09956 23.6387ZM14.6535 7.25886C16.3161 7.83155 17.532 8.69001 17.2931 10.2869C17.1202 11.4559 16.4721 12.0219 15.6117 12.2203C16.7931 12.8353 17.3943 13.7786 16.8216 15.4137C16.1109 17.4446 14.4225 17.616 12.1771 17.191L11.6322 19.3749L10.3154 19.0467L10.8532 16.8921C10.512 16.8074 10.1632 16.7174 9.80388 16.6199L9.2642 18.7846L7.94893 18.4564L8.49387 16.2684C8.37094 16.237 8.24724 16.2047 8.12267 16.1722C7.93557 16.1234 7.74651 16.0741 7.55514 16.0262L5.84158 15.599L6.49528 14.0917C6.49528 14.0917 7.46551 14.3497 7.45238 14.3306C7.82517 14.4229 7.99056 14.1798 8.05582 14.0178L8.91691 10.5656C8.94926 10.5734 8.98094 10.5813 9.01149 10.589C9.02664 10.5928 9.04151 10.5965 9.05605 10.6001C9.00355 10.5791 8.95592 10.5656 8.91916 10.5562L9.53348 8.09182C9.5496 7.81204 9.45322 7.45913 8.91991 7.32599C8.94054 7.31212 7.96356 7.08822 7.96356 7.08822L8.31385 5.68182L10.1298 6.13524L10.1283 6.14199C10.4013 6.20987 10.6826 6.27438 10.9691 6.33964L11.5088 4.17716L12.8248 4.50532L12.296 6.62542C12.6493 6.70605 13.0048 6.78744 13.351 6.8737L13.8761 4.76747L15.1928 5.09563L14.6535 7.25886ZM10.4885 15.102C11.5629 15.3857 13.9112 16.006 14.2845 14.5058C14.667 12.9719 12.3897 12.4608 11.2784 12.2115C11.1542 12.1836 11.0446 12.159 10.9549 12.1366L10.2318 15.0353C10.3055 15.0536 10.3918 15.0764 10.4885 15.102ZM11.5017 10.8663C12.3975 11.1054 14.3514 11.6268 14.6918 10.2633C15.0393 8.86865 13.1402 8.44823 12.2128 8.24291C12.1084 8.21982 12.0164 8.19944 11.9412 8.18071L11.2857 10.8097C11.3476 10.8252 11.4203 10.8446 11.5017 10.8663Z"
          fill={color ?? '#F7931A'}
        />
      </g>
      <defs>
        <clipPath id="clip0_486_568">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
