interface DefaultPrecision {
  number: [number, number]
  currency: [number, number]
  cryptoCurrency: [number, number]
}

export const defaultPrecision: DefaultPrecision = {
  number: [0, 20],
  currency: [0, 2],
  cryptoCurrency: [0, 18],
}

interface DefaultCurrencies {
  currency: string
  cryptoCurrency: string
}

export const defaultCurrencies: DefaultCurrencies = {
  currency: 'USD',
  cryptoCurrency: 'USDT',
}

export const defaultLocale = 'en-US'

interface DefaultDateFormatterOptions {
  full: Intl.DateTimeFormatOptions
  dateOnly: Intl.DateTimeFormatOptions
}

export const defaultDateFormatterOptions: DefaultDateFormatterOptions = {
  full: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  dateOnly: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
}
