import { Icon, IconName } from '../Icon'
import { Text, TextS } from '../Typo/Text'

export interface TokenInfoProps {
  name: string
  currency?: string
  icon?: IconName
  image?: string
}

export function TokenInfo({ name, currency, icon, image }: TokenInfoProps) {
  return (
    <div className="flex gap-4">
      <div className="flex h-[48px] w-[48px] items-center justify-center">
        {icon && <Icon className="rounded-xl p-1" name={icon} size={38} />}
        {image && <img className="rounded-xl" src={image} alt={name} />}
      </div>

      <div className="flex flex-col items-start justify-center">
        <Text isBold>{name}</Text>

        {currency && <TextS className="text-neutral-300">{currency}</TextS>}
      </div>
    </div>
  )
}
