import clsx from 'clsx'
import { FC, HTMLAttributes } from 'react'

export interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  isBold?: boolean
  className?: string
}

interface HeaderTemplate extends HeaderProps {
  as: 'h1' | 'h2' | 'h3'
}

function HeaderTemplate({ as, children, isBold, className }: HeaderTemplate) {
  const As = as
  return (
    <As
      className={clsx(
        as === 'h1' && 'text-2xl sm:text-3xl',
        as === 'h2' && 'text-xl sm:text-2xl',
        as === 'h3' && 'text-lg sm:text-xl',
        (isBold && as === 'h3' && 'font-semibold sm:font-medium') ||
          (isBold && 'font-medium') ||
          (as === 'h3' && 'font-normal sm:font-light') ||
          'font-light',
        className
      )}
    >
      {children}
    </As>
  )
}

export const H1: FC<HeaderProps> = ({ children, isBold, className }) => {
  return (
    <HeaderTemplate as="h1" isBold={isBold} className={className}>
      {children}
    </HeaderTemplate>
  )
}

export const H2: FC<HeaderProps> = ({ children, isBold, className }) => {
  return (
    <HeaderTemplate as="h2" isBold={isBold} className={className}>
      {children}
    </HeaderTemplate>
  )
}

export const H3: FC<HeaderProps> = ({ children, isBold, className }) => {
  return (
    <HeaderTemplate as="h3" isBold={isBold} className={className}>
      {children}
    </HeaderTemplate>
  )
}
