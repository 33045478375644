import { AssetProps } from './index'

export default function TelegramAnnouncements({
  size,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_964_1136)">
        <path
          d="M24.286 1.63602C24.2857 1.49635 24.2777 1.35681 24.262 1.21802C24.233 1.03763 24.1926 0.859272 24.141 0.684017C24.0913 0.501207 23.9867 0.338056 23.8413 0.216621C23.6959 0.0951867 23.5168 0.0213241 23.328 0.00501607C22.9468 -0.00460702 22.5661 0.037101 22.196 0.129017C21.434 0.351017 20.68 0.609017 19.94 0.899017C17.871 1.71102 15.804 2.52802 13.75 3.37902C11.82 4.17902 9.90201 5.01402 7.99001 5.85902C6.38101 6.56902 4.79001 7.31401 3.19001 8.05101C2.84607 8.20427 2.51209 8.37895 2.19001 8.57402C2.07812 8.62328 1.98288 8.7039 1.91581 8.80613C1.84874 8.90835 1.81272 9.02781 1.81208 9.15007C1.81145 9.27234 1.84623 9.39216 1.91224 9.49508C1.97824 9.59799 2.07263 9.67959 2.18401 9.73001C2.50985 9.93103 2.86002 10.0896 3.22601 10.202C4.16301 10.48 5.11301 10.713 6.05601 10.971C6.50677 11.0899 6.97693 11.1164 7.43818 11.0489C7.89944 10.9813 8.34227 10.8212 8.74001 10.578C9.93001 9.90401 11.095 9.17802 12.26 8.46402C14.008 7.38602 15.747 6.29402 17.49 5.20902C17.646 5.10902 17.802 5.00902 17.963 4.92402C18.042 4.88352 18.133 4.87296 18.2192 4.89428C18.3054 4.91559 18.381 4.96735 18.432 5.04002C18.539 5.20002 18.462 5.31602 18.342 5.42302C18.303 5.45702 18.266 5.49202 18.228 5.52302C17.428 6.25802 16.618 6.98901 15.82 7.73101C15.26 8.25201 14.714 8.79002 14.163 9.32102C13.3321 10.0603 12.5803 10.8841 11.92 11.779C11.8089 11.8936 11.725 12.0316 11.6745 12.183C11.6241 12.3344 11.6083 12.4952 11.6285 12.6535C11.6487 12.8118 11.7042 12.9636 11.7911 13.0975C11.8779 13.2314 11.9937 13.344 12.13 13.427C12.471 13.705 12.83 13.958 13.191 14.218C14.946 15.5 16.76 16.692 18.616 17.818C19.0235 18.1115 19.4845 18.3225 19.973 18.439C20.2383 18.5143 20.521 18.5 20.7773 18.3984C21.0336 18.2968 21.2493 18.1136 21.391 17.877C21.5559 17.5921 21.6728 17.2819 21.737 16.959C22.179 14.713 22.624 12.469 23.023 10.215C23.377 8.21502 23.684 6.20801 23.992 4.20101C24.122 3.35102 24.204 2.49402 24.309 1.63902H24.281"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M10.31 14.235C10.3514 14.1892 10.3831 14.1355 10.403 14.0771C10.423 14.0186 10.4308 13.9567 10.426 13.8952C10.4212 13.8336 10.4039 13.7737 10.3751 13.7191C10.3464 13.6644 10.3068 13.6162 10.2588 13.5774C10.2108 13.5386 10.1554 13.51 10.0959 13.4933C10.0365 13.4766 9.97424 13.4723 9.91304 13.4805C9.85185 13.4887 9.79297 13.5093 9.74001 13.541C9.68704 13.5727 9.64111 13.6149 9.60502 13.665L8.66202 14.833C8.6206 14.8788 8.58895 14.9326 8.56901 14.991C8.54907 15.0494 8.54125 15.1113 8.54605 15.1729C8.55084 15.2344 8.56814 15.2944 8.59689 15.349C8.62564 15.4036 8.66523 15.4518 8.71324 15.4907C8.76125 15.5295 8.81668 15.5581 8.87613 15.5748C8.93558 15.5914 8.9978 15.5958 9.05899 15.5876C9.12019 15.5794 9.17907 15.5588 9.23203 15.5271C9.28499 15.4953 9.33092 15.4531 9.36702 15.403L10.31 14.235Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M11.548 18.958L10.148 18.406C10.0927 18.3842 10.0335 18.3734 9.97404 18.3744C9.91453 18.3754 9.8558 18.3881 9.8012 18.4117C9.7466 18.4354 9.6972 18.4696 9.65582 18.5124C9.61443 18.5552 9.58188 18.6057 9.56001 18.661C9.53815 18.7164 9.5274 18.7755 9.52838 18.835C9.52936 18.8945 9.54206 18.9532 9.56574 19.0078C9.58941 19.0624 9.62362 19.1118 9.66639 19.1532C9.70916 19.1946 9.75966 19.2272 9.81501 19.249L11.21 19.802C11.2658 19.8258 11.3257 19.8382 11.3864 19.8384C11.447 19.8387 11.5071 19.8267 11.563 19.8034C11.619 19.78 11.6697 19.7457 11.7121 19.7025C11.7546 19.6592 11.788 19.6078 11.8103 19.5515C11.8326 19.4951 11.8434 19.4348 11.842 19.3742C11.8407 19.3136 11.8272 19.2539 11.8024 19.1986C11.7775 19.1433 11.7419 19.0935 11.6975 19.0522C11.6532 19.0108 11.601 18.9788 11.544 18.958"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M9.92697 17.329L11.256 16.979C11.3707 16.9468 11.4681 16.8708 11.5273 16.7675C11.5865 16.6641 11.6027 16.5416 11.5724 16.4264C11.5421 16.3112 11.4677 16.2125 11.3653 16.1516C11.2629 16.0908 11.1407 16.0726 11.025 16.101L9.69597 16.451C9.58127 16.4832 9.48382 16.5592 9.42463 16.6625C9.36544 16.7659 9.34926 16.8884 9.37957 17.0036C9.40988 17.1188 9.48425 17.2175 9.58666 17.2784C9.68906 17.3393 9.81129 17.3574 9.92697 17.329Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M5.42299 14.246C5.32171 14.2763 5.22634 14.3237 5.14099 14.386V14.381L5.12199 14.397C5.1022 14.4114 5.08318 14.4267 5.06499 14.443L0.349991 17.594C0.152769 17.8547 0.0271356 18.1623 -0.0144389 18.4865C-0.0560134 18.8107 -0.0120833 19.1401 0.112991 19.442C0.331991 20.275 0.805991 20.888 1.22899 20.934L2.20099 21.007L3.57199 23.101C3.68046 23.2665 3.85024 23.3822 4.04396 23.4225C4.23769 23.4628 4.4395 23.4245 4.60499 23.316C4.77049 23.2076 4.88611 23.0378 4.92643 22.8441C4.96675 22.6503 4.92846 22.4485 4.81999 22.283L4.07699 21.148L6.89399 21.36C6.91199 21.36 6.92899 21.367 6.94699 21.368L6.98099 21.374V21.366C7.08585 21.3786 7.1921 21.3729 7.29499 21.349C8.24999 21.098 8.60599 19.303 8.08999 17.339C7.57399 15.375 6.37999 13.989 5.42499 14.239M7.09999 20.615C6.66899 20.728 6.11299 20.121 5.68999 19.158C5.8133 19.0013 5.89371 18.8151 5.92334 18.6179C5.95297 18.4207 5.9308 18.2191 5.85899 18.033C5.72199 17.514 5.41199 17.154 5.13299 17.133C4.98799 16.001 5.16299 15.11 5.61799 14.99C6.19499 14.839 6.99299 15.976 7.40299 17.53C7.81299 19.084 7.67599 20.467 7.10299 20.62"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_964_1136">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
