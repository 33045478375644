import { AssetProps } from './index'

export default function DuplicateFilled({
  size = 16,
  color,
  className,
}: AssetProps) {
  return (
    <svg
      width={size}
      height={size * (11.948 / 13.845)}
      viewBox="0 0 11.948 13.845"
      className={className}
    >
      <path
        d="M-483.051-1691.232v-10h8v10Zm9.949-2.891v-8.954h-6.808v-2h8.807v10.954Z"
        transform="translate(483.051 1705.077)"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
