import clsx from 'clsx'
import { TextareaHTMLAttributes } from 'react'

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  errorMessage?: string
  label?: string
}

export function TextArea({
  label,
  placeholder,
  errorMessage,
  name,
  rows = 3,
  required = true,
  disabled = false,
}: TextAreaProps) {
  const isError = errorMessage && errorMessage.length > 0

  return (
    <div className="w-full">
      <label className="block pb-2 text-sm font-semibold">
        {label}
        {label && required ? '*' : ''}
      </label>
      <textarea
        name={name}
        rows={rows}
        className={clsx(
          'appearance-none',
          'focus:outline-none',
          'focus:border-blue-500',
          'focus:border-2',
          'border',
          'border-neutral-400',
          'bg-neutral-700',
          'rounded',
          'w-full',
          'p-px',
          'focus:p-0',
          'focus:pl-[9px]',
          'active:py-[7px]',
          'focus:py-[7px]',
          'min-h-[112px]',
          'resize-none',
          'disabled:py-[7px]',
          'active:py-[7px]',
          'py-2',
          'h-10',
          'text-sm',
          'pl-2.5',
          'disabled:cursor-not-allowed',
          'caret-blue-500',
          'placeholder-neutral-300',
          isError &&
            clsx(
              'border-red-500',
              'focus:border-red-500',
              'focus:p-px',
              'focus:pl-2.5',
              'active:pl-2.5',
              'focus:border',
              'caret-red-500'
            )
        )}
        placeholder={placeholder}
        disabled={disabled}
      />
      {isError && (
        <div className="pt-2 text-sm text-red-100">{errorMessage}</div>
      )}
    </div>
  )
}
