import { Button, ButtonJustify, ButtonVariant } from '../Button'

import { SupportedLocale } from './story-data'
import { Dropdown } from './dropdown'

export interface LocaleData {
  supportedLocales: SupportedLocale[]
  currentLocale: string
  changePathnameLocale: (newLocale: string) => string
  routeWithLocale: (routePath: string) => string
}

export interface LanguageProps {
  data: LocaleData
}

export default function Language({ data }: LanguageProps) {
  const { supportedLocales, currentLocale, changePathnameLocale } = data

  return (
    <Dropdown buttonLabel={currentLocale.toUpperCase()} fullWidthButton>
      <ul className="flex flex-col">
        {supportedLocales.map(locale => (
          <li key={locale} className="w-full">
            <Button
              onClick={() => {
                window.location.href = changePathnameLocale(locale)
              }}
              variant={ButtonVariant.Ghost}
              justify={ButtonJustify.Start}
              className="w-full uppercase"
            >
              {locale}
            </Button>
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}
