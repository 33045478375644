import { HTMLAttributes } from 'react'

import { H2 } from '../Typo/Header'
import { Text } from '../Typo/Text'

export interface CallToActionSectionProps
  extends HTMLAttributes<HTMLDivElement> {
  title: string
  description?: string
}

export function CallToActionSection({
  title,
  description,
  children,
}: CallToActionSectionProps) {
  return (
    <section className="flex flex-col gap-8">
      <header className="flex flex-col gap-4">
        <H2 isBold>{title}</H2>
        {description && <Text>{description}</Text>}
      </header>
      {children}
    </section>
  )
}
