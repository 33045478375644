import { AssetProps } from './index'

export default function SuccessAlertIcon({ className }: AssetProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 24C5.37931 24 0 18.6207 0 12C0 5.37931 5.37931 0 12 0C18.6207 0 24 5.37931 24 12C24 18.6207 18.6207 24 12 24ZM12 1.65517C6.28966 1.65517 1.65517 6.28966 1.65517 12C1.65517 17.7103 6.28966 22.3448 12 22.3448C17.7103 22.3448 22.3448 17.7103 22.3448 12C22.3448 6.28966 17.7103 1.65517 12 1.65517Z"
        fill="#0CA83B"
      />
      <path
        d="M10.1462 17.1641C9.91448 17.1641 9.69931 17.0648 9.53379 16.8993L6.47172 13.5724C6.15724 13.2414 6.19034 12.7117 6.52138 12.3972C6.85241 12.0993 7.38207 12.1159 7.69655 12.4469L10.1462 15.1117L16.3034 8.42483C16.6179 8.09379 17.131 8.07724 17.4786 8.37517C17.8097 8.68965 17.8428 9.20276 17.5283 9.55034L10.7586 16.8993C10.6097 17.0648 10.3779 17.1641 10.1462 17.1641Z"
        fill="#0CA83B"
      />
    </svg>
  )
}
