import { AssetProps } from './index'

export default function TensetLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '37'}
      height={size ? (size * 48) / 37 : '48'}
      viewBox="0 0 37 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_62_228)">
        <path
          d="M25.651 22.8061L27.2495 21.2223C27.8398 20.6376 27.8398 19.6629 27.2495 19.0782L19.1829 11.1107C18.5927 10.5259 17.6089 10.5259 17.0187 11.1107L8.95202 19.1025C8.36178 19.6873 8.36178 20.6619 8.95202 21.2467L10.5506 22.8305V29.1411V35.7929L8.95202 37.3766C8.36178 37.9614 8.36178 38.936 8.95202 39.5208L17.0187 47.5127C17.6089 48.0975 18.5927 48.0975 19.1829 47.5127L27.2495 39.5208C27.8398 38.936 27.8398 37.9614 27.2495 37.3766L25.651 35.7929V29.1411V22.8061ZM21.1504 29.1411V35.1838C21.1504 35.5736 20.9782 35.9634 20.7077 36.2558L18.1254 38.8142L15.5431 36.2558C15.2479 35.9634 15.1004 35.598 15.1004 35.1838V29.1411V23.4152C15.1004 23.0254 15.2725 22.6355 15.5431 22.3431L18.1254 19.7848L20.7077 22.3431C21.0028 22.6355 21.1504 23.001 21.1504 23.4152V29.1411Z"
          fill={color ?? '#FF63D7'}
        />
        <path
          d="M35.7097 7.87005L28.3808 0.609134C27.7906 0.0243622 26.8069 0.0243622 26.2166 0.609134L25.2575 1.55939C24.9624 1.85177 24.5935 1.99797 24.1754 1.99797H18.1008H12.0262C11.6327 1.99797 11.2392 1.82741 10.9441 1.55939L9.98495 0.609134C9.39471 0.0243622 8.41097 0.0243622 7.82073 0.609134L0.491876 7.87005C-0.0983664 8.45482 -0.0983664 9.42944 0.491876 10.0142L3.24634 12.7431C3.83659 13.3279 4.82032 13.3279 5.41057 12.7431L11.313 6.89543C11.6081 6.60304 11.977 6.45685 12.3951 6.45685H18.1008H23.8065C24.2 6.45685 24.5935 6.62741 24.8886 6.89543L30.791 12.7431C31.3813 13.3279 32.365 13.3279 32.9552 12.7431L35.7097 10.0142C36.2999 9.42944 36.2999 8.45482 35.7097 7.87005Z"
          fill={color ?? '#FF63D7'}
        />
      </g>
      <defs>
        <clipPath id="clip0_62_228">
          <rect width="37" height="48" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
