import { AssetProps } from './index'

export default function Gear({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '14'}
      height={size ?? '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_5711_34337)">
        <path
          d="M13.1921 5.9134L11.9786 5.64464C11.8646 5.21299 11.6935 4.80578 11.4736 4.43114L12.1415 3.38867C12.2718 3.19321 12.2392 2.9326 12.0763 2.76157L11.2375 1.92271C11.0746 1.75982 10.814 1.72724 10.6104 1.85755L9.56789 2.52538C9.19325 2.30549 8.7779 2.1426 8.35439 2.02044L8.08563 0.806941C8.03677 0.578901 7.83316 0.416016 7.59698 0.416016H6.40791C6.17173 0.416016 5.96812 0.578901 5.91926 0.806941L5.6505 2.02044C5.21885 2.13446 4.81164 2.30549 4.437 2.52538L3.39453 1.85755C3.19907 1.72724 2.93845 1.75982 2.76742 1.92271L1.92856 2.76157C1.76568 2.92445 1.7331 3.18507 1.86341 3.38867L2.53124 4.43114C2.31135 4.80578 2.14846 5.22113 2.0263 5.64464L0.8128 5.9134C0.58476 5.96226 0.421875 6.16587 0.421875 6.40205V7.59112C0.421875 7.8273 0.58476 8.03091 0.8128 8.07977L2.0263 8.34853C2.14032 8.78018 2.31135 9.18739 2.53124 9.56203L1.86341 10.6126C1.7331 10.8081 1.76568 11.0687 1.92856 11.2397L2.76742 12.0786C2.93031 12.2415 3.19093 12.2741 3.39453 12.1438L4.437 11.4759C4.81164 11.6958 5.22699 11.8587 5.6505 11.9809L5.91926 13.1944C5.96812 13.4224 6.17173 13.5853 6.40791 13.5853H7.59698C7.83316 13.5853 8.03677 13.4224 8.08563 13.1944L8.35439 11.9809C8.78604 11.8669 9.19325 11.6958 9.56789 11.4759L10.6104 12.1438C10.8058 12.2741 11.0664 12.2415 11.2375 12.0786L12.0763 11.2397C12.2392 11.0769 12.2718 10.8162 12.1415 10.6126L11.4736 9.57017C11.6935 9.19554 11.8564 8.78018 11.9786 8.35668L13.1921 8.08792C13.4201 8.03905 13.583 7.83544 13.583 7.59926V6.4102C13.583 6.17401 13.4201 5.97041 13.1921 5.92154V5.9134ZM6.9943 9.26884C5.73194 9.26884 4.71391 8.2508 4.71391 6.98844C4.71391 5.72608 5.73194 4.70805 6.9943 4.70805C8.25666 4.70805 9.2747 5.72608 9.2747 6.98844C9.2747 8.2508 8.25666 9.26884 6.9943 9.26884Z"
          stroke={color ?? '#6E9FFF'}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_5711_34337">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
