import { AssetProps } from './index'
export function TglpLogotype({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '188'}
      height={size ? (size * 48) / 188 : '48'}
      viewBox="0 0 188 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <FirstPart />
      <path
        d="M99.8861 35.0003V24.7803H101.874V35.0003H99.8861ZM93.1801 35.0003V24.7803H95.1681V35.0003H93.1801ZM94.8041 30.7583V29.0083H100.25V30.7583H94.8041Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M109.121 31.8643V30.1423H111.109C111.51 30.1423 111.846 30.063 112.117 29.9043C112.397 29.7457 112.607 29.5263 112.747 29.2463C112.896 28.9663 112.971 28.6443 112.971 28.2803C112.971 27.9163 112.896 27.5943 112.747 27.3143C112.607 27.0343 112.397 26.8197 112.117 26.6703C111.846 26.5117 111.51 26.4323 111.109 26.4323H109.121V24.7103H110.941C111.799 24.7103 112.527 24.8503 113.125 25.1303C113.731 25.4103 114.193 25.8117 114.511 26.3343C114.828 26.8477 114.987 27.459 114.987 28.1683V28.3923C114.987 29.1017 114.828 29.7177 114.511 30.2403C114.193 30.7537 113.731 31.155 113.125 31.4443C112.527 31.7243 111.799 31.8643 110.941 31.8643H109.121ZM107.413 35.0003V24.7103H109.401V35.0003H107.413Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M116.627 35.0003V24.7803H118.615V35.0003H116.627ZM118.335 35.0003V33.2503H122.745V35.0003H118.335Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M123.467 35.0003L126.953 24.7803H130.201L133.785 35.0003H131.741L128.703 26.1383L129.417 26.4463H127.667L128.395 26.1383L125.455 35.0003H123.467ZM125.903 32.2843L126.533 30.6043H130.677L131.293 32.2843H125.903Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M136.458 35.0003V26.2503H138.446V35.0003H136.458ZM133.504 26.5303V24.7803H141.4V26.5303H133.504Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M142.987 35.0003V24.7803H144.975V35.0003H142.987ZM144.695 30.7443V29.0643L148.783 29.0503V30.7303L144.695 30.7443ZM144.695 26.4603V24.7803H148.979V26.4603H144.695Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M155.582 35.2663C154.658 35.2663 153.855 35.103 153.174 34.7763C152.502 34.4497 151.942 34.025 151.494 33.5023C151.055 32.9703 150.724 32.401 150.5 31.7943C150.285 31.1783 150.178 30.5857 150.178 30.0163V29.7083C150.178 29.083 150.29 28.4623 150.514 27.8463C150.738 27.221 151.074 26.6563 151.522 26.1523C151.979 25.6483 152.544 25.247 153.216 24.9483C153.888 24.6403 154.676 24.4863 155.582 24.4863C156.478 24.4863 157.262 24.6403 157.934 24.9483C158.606 25.247 159.166 25.6483 159.614 26.1523C160.071 26.6563 160.412 27.221 160.636 27.8463C160.86 28.4623 160.972 29.083 160.972 29.7083V30.0163C160.972 30.5857 160.86 31.1783 160.636 31.7943C160.421 32.401 160.09 32.9703 159.642 33.5023C159.203 34.025 158.643 34.4497 157.962 34.7763C157.29 35.103 156.496 35.2663 155.582 35.2663ZM155.582 33.4183C156.104 33.4183 156.571 33.325 156.982 33.1383C157.402 32.9517 157.756 32.695 158.046 32.3683C158.344 32.0323 158.568 31.6543 158.718 31.2343C158.876 30.805 158.956 30.3523 158.956 29.8763C158.956 29.363 158.876 28.8917 158.718 28.4623C158.568 28.033 158.344 27.6597 158.046 27.3423C157.756 27.025 157.402 26.7777 156.982 26.6003C156.562 26.423 156.095 26.3343 155.582 26.3343C155.059 26.3343 154.588 26.423 154.168 26.6003C153.748 26.7777 153.388 27.025 153.09 27.3423C152.8 27.6597 152.576 28.033 152.418 28.4623C152.268 28.8917 152.194 29.363 152.194 29.8763C152.194 30.3523 152.268 30.805 152.418 31.2343C152.576 31.6543 152.8 32.0323 153.09 32.3683C153.388 32.695 153.748 32.9517 154.168 33.1383C154.588 33.325 155.059 33.4183 155.582 33.4183Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M162.893 35.0003V24.7103H164.881V35.0003H162.893ZM169.137 35.0003L166.001 30.5483H168.227L171.461 35.0003H169.137ZM164.293 31.5843V29.9743H166.897C167.261 29.9743 167.574 29.8997 167.835 29.7503C168.106 29.601 168.316 29.391 168.465 29.1203C168.614 28.8497 168.689 28.5417 168.689 28.1963C168.689 27.8417 168.614 27.529 168.465 27.2583C168.316 26.9877 168.106 26.7777 167.835 26.6283C167.574 26.479 167.261 26.4043 166.897 26.4043H164.293V24.7103H166.687C167.508 24.7103 168.218 24.8317 168.815 25.0743C169.422 25.317 169.888 25.6857 170.215 26.1803C170.542 26.675 170.705 27.2957 170.705 28.0423V28.2663C170.705 29.0223 170.537 29.643 170.201 30.1283C169.874 30.6137 169.412 30.9777 168.815 31.2203C168.218 31.463 167.508 31.5843 166.687 31.5843H164.293Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M172.969 35.0003V24.7803H175.685L178.247 30.8843H178.499L181.047 24.7803H183.805V35.0003H181.831V26.2783L182.083 26.3063L179.325 32.9143H177.295L174.523 26.3063L174.789 26.2783V35.0003H172.969Z"
        fill={color ?? '#FF63D7'}
      />
    </svg>
  )
}

function FirstPart({ color }: AssetProps) {
  return (
    <>
      <path
        d="M78.7823 19.8519V14.9818H79.9817V19.8519H78.7823ZM77 15.1377V14.1636H81.764V15.1377H77Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M82.7215 19.8519V14.1636H83.8956V19.8519H82.7215ZM83.7267 19.8519V18.9169H86.5226V19.8519H83.7267ZM83.7267 17.4286V16.4935H86.303V17.4286H83.7267ZM83.7267 15.0987V14.1636H86.455V15.0987H83.7267Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M87.7533 19.8519V14.1636H89.7214L92.3146 18.9325H92.5934L92.4244 19.0727V14.1636H93.5563V19.8519H91.5798L88.9866 15.0831H88.6994L88.8683 14.9429V19.8519H87.7533Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M97.1816 20C96.6354 20 96.1736 19.9195 95.7963 19.7584C95.4247 19.5974 95.1403 19.3766 94.9432 19.0961C94.7461 18.8104 94.6476 18.4883 94.6476 18.1299H95.8217C95.8217 18.2805 95.8667 18.4286 95.9568 18.574C96.0469 18.7143 96.1905 18.8286 96.3876 18.9169C96.5903 19.0052 96.855 19.0494 97.1816 19.0494C97.4744 19.0494 97.7194 19.0104 97.9165 18.9325C98.1136 18.8545 98.26 18.7532 98.3557 18.6286C98.4515 18.4987 98.4993 18.3532 98.4993 18.1922C98.4993 17.9896 98.4064 17.8286 98.2206 17.7091C98.0347 17.5844 97.7476 17.5065 97.359 17.4753L96.8184 17.4364C96.2271 17.3896 95.7541 17.2208 95.3993 16.9299C95.0502 16.6338 94.8756 16.2442 94.8756 15.761C94.8756 15.3974 94.9685 15.0857 95.1544 14.826C95.3402 14.561 95.5992 14.3584 95.9315 14.2182C96.2694 14.0727 96.6635 14 97.114 14C97.5814 14 97.9841 14.0753 98.3219 14.226C98.6598 14.3766 98.9217 14.5896 99.1075 14.8649C99.2933 15.1351 99.3862 15.4545 99.3862 15.8234H98.2121C98.2121 15.6675 98.1699 15.5247 98.0854 15.3948C98.0066 15.2597 97.8855 15.1532 97.7222 15.0753C97.5645 14.9922 97.3618 14.9506 97.114 14.9506C96.8775 14.9506 96.6804 14.987 96.5228 15.0597C96.3651 15.1325 96.2468 15.2312 96.168 15.3558C96.0892 15.4805 96.0497 15.6156 96.0497 15.761C96.0497 15.9377 96.1201 16.0935 96.2609 16.2286C96.4017 16.3584 96.6241 16.4338 96.9282 16.4545L97.4688 16.5013C97.908 16.5325 98.2938 16.6182 98.626 16.7584C98.9583 16.8987 99.2145 17.0883 99.3947 17.3273C99.5805 17.5662 99.6734 17.8545 99.6734 18.1922C99.6734 18.5506 99.5721 18.8675 99.3694 19.1429C99.1666 19.413 98.8766 19.6234 98.4993 19.774C98.1277 19.9247 97.6884 20 97.1816 20Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M100.77 19.8519V14.1636H101.944V19.8519H100.77ZM101.775 19.8519V18.9169H104.571V19.8519H101.775ZM101.775 17.4286V16.4935H104.352V17.4286H101.775ZM101.775 15.0987V14.1636H104.504V15.0987H101.775Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M107.018 19.8519V14.9818H108.218V19.8519H107.018ZM105.236 15.1377V14.1636H110V15.1377H105.236Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M9.01825 35.2663C8.14092 35.2663 7.36625 35.1217 6.69425 34.8323C6.03158 34.5337 5.47158 34.137 5.01425 33.6423C4.56625 33.1383 4.22558 32.5737 3.99225 31.9483C3.76825 31.3137 3.65625 30.6557 3.65625 29.9743V29.6663C3.65625 29.0223 3.76825 28.3923 3.99225 27.7763C4.21625 27.1603 4.54292 26.605 4.97225 26.1103C5.41092 25.6157 5.94758 25.2237 6.58225 24.9343C7.22625 24.6357 7.96358 24.4863 8.79425 24.4863C9.69025 24.4863 10.4836 24.6543 11.1743 24.9903C11.8649 25.317 12.4202 25.779 12.8403 26.3763C13.2603 26.9643 13.5076 27.6503 13.5823 28.4343H11.5803C11.5149 27.9957 11.3516 27.6223 11.0903 27.3143C10.8289 26.997 10.4976 26.7543 10.0963 26.5863C9.70425 26.4183 9.27025 26.3343 8.79425 26.3343C8.31825 26.3343 7.88425 26.4183 7.49225 26.5863C7.10958 26.7543 6.78292 26.9923 6.51225 27.3003C6.24158 27.6083 6.03158 27.977 5.88225 28.4063C5.74225 28.8357 5.67225 29.3117 5.67225 29.8343C5.67225 30.4037 5.75158 30.9123 5.91025 31.3603C6.06892 31.8083 6.29292 32.191 6.58225 32.5083C6.87158 32.8163 7.22158 33.0543 7.63225 33.2223C8.04292 33.381 8.50492 33.4603 9.01825 33.4603C9.69958 33.4603 10.2969 33.3203 10.8103 33.0403C11.3236 32.751 11.6969 32.3777 11.9303 31.9203L11.5803 33.5303V30.8983H13.4143V33.1663C12.9756 33.829 12.3783 34.347 11.6223 34.7203C10.8663 35.0843 9.99825 35.2663 9.01825 35.2663ZM8.69625 31.2903V29.8063H14.2683V31.2903H8.69625Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M15.9067 35.0003V24.7803H17.8527V35.0003H15.9067ZM17.5727 35.0003V33.3203H22.2067V35.0003H17.5727ZM17.5727 30.6463V28.9663H21.8427V30.6463H17.5727ZM17.5727 26.4603V24.7803H22.0947V26.4603H17.5727Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M24.2465 35.0003V24.7803H26.9625L29.5245 30.8843H29.7765L32.3245 24.7803H35.0825V35.0003H33.1085V26.2783L33.3605 26.3063L30.6025 32.9143H28.5725L25.8005 26.3063L26.0665 26.2783V35.0003H24.2465Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M40.6254 35.0003V24.7803H42.6134V35.0003H40.6254ZM42.3334 35.0003V33.2503H46.7434V35.0003H42.3334Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M47.4651 35.0003L50.9511 24.7803H54.1991L57.7831 35.0003H55.7391L52.7011 26.1383L53.4151 26.4463H51.6651L52.3931 26.1383L49.4531 35.0003H47.4651ZM49.9011 32.2843L50.5311 30.6043H54.6751L55.2911 32.2843H49.9011Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M63.1461 35.2663C62.1941 35.2663 61.3914 35.0843 60.7381 34.7203C60.0847 34.3563 59.5854 33.8477 59.2401 33.1943C58.9041 32.5317 58.7361 31.757 58.7361 30.8703V24.7803H60.7241V30.9823C60.7241 31.4677 60.8174 31.897 61.0041 32.2703C61.1907 32.6343 61.4614 32.919 61.8161 33.1243C62.1801 33.3203 62.6234 33.4183 63.1461 33.4183C63.6594 33.4183 64.0934 33.3203 64.4481 33.1243C64.8121 32.919 65.0827 32.6343 65.2601 32.2703C65.4467 31.9063 65.5401 31.477 65.5401 30.9823V24.7803H67.5281V30.8703C67.5281 31.757 67.3601 32.5317 67.0241 33.1943C66.6974 33.8477 66.2074 34.3563 65.5541 34.7203C64.9007 35.0843 64.0981 35.2663 63.1461 35.2663Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M69.8559 35.0003V24.7803H73.1179L77.4159 33.3483H77.8779L77.5979 33.6003V24.7803H79.4739V35.0003H76.1979L71.8999 26.4323H71.4239L71.7039 26.1803V35.0003H69.8559Z"
        fill={color ?? '#FF63D7'}
      />
      <path
        d="M86.6025 35.2663C85.6785 35.2663 84.8852 35.103 84.2225 34.7763C83.5599 34.4497 83.0185 34.025 82.5985 33.5023C82.1879 32.9797 81.8845 32.4103 81.6885 31.7943C81.4925 31.1783 81.3945 30.5857 81.3945 30.0163V29.7083C81.3945 29.0737 81.4925 28.4483 81.6885 27.8323C81.8939 27.2163 82.2065 26.6563 82.6265 26.1523C83.0465 25.6483 83.5785 25.247 84.2225 24.9483C84.8759 24.6403 85.6459 24.4863 86.5325 24.4863C87.4565 24.4863 88.2592 24.6543 88.9405 24.9903C89.6312 25.317 90.1819 25.7837 90.5925 26.3903C91.0032 26.9877 91.2505 27.6877 91.3345 28.4903H89.3325C89.2579 28.0237 89.0899 27.6317 88.8285 27.3143C88.5765 26.9877 88.2545 26.745 87.8625 26.5863C87.4705 26.4183 87.0272 26.3343 86.5325 26.3343C86.0285 26.3343 85.5805 26.423 85.1885 26.6003C84.7965 26.7683 84.4699 27.011 84.2085 27.3283C83.9472 27.6457 83.7465 28.019 83.6065 28.4483C83.4759 28.8777 83.4105 29.3537 83.4105 29.8763C83.4105 30.3803 83.4759 30.847 83.6065 31.2763C83.7465 31.7057 83.9519 32.0837 84.2225 32.4103C84.4932 32.7277 84.8245 32.975 85.2165 33.1523C85.6179 33.3297 86.0799 33.4183 86.6025 33.4183C87.3679 33.4183 88.0119 33.2317 88.5345 32.8583C89.0665 32.4757 89.3885 31.9437 89.5005 31.2623H91.5025C91.4092 31.9903 91.1619 32.6577 90.7605 33.2643C90.3592 33.871 89.8085 34.3563 89.1085 34.7203C88.4085 35.0843 87.5732 35.2663 86.6025 35.2663Z"
        fill={color ?? '#FF63D7'}
      />
    </>
  )
}
