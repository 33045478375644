import { AssetProps } from './index'

export default function FameLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.10422 9.97044H3.88017H3.25399L2.86476 11.1448L2.17942 13.2696H5.32972L5.71566 12.0974H3.95539L4.25406 11.1448H6.7161L7.10422 9.97044ZM3.65234 13.0075H2.26304L1.64999 14.8654H3.03929L3.65234 13.0075Z"
        fill={color ?? '#FAFAFA'}
      />
      <path
        d="M18.4797 9.97408H22.3504L21.9677 11.1448H19.49L19.2632 11.8397H21.0465L20.6635 13.0104L18.8799 13.0111L18.6571 13.6936H21.3466L20.9636 14.8658H16.8812L18.4797 9.97408Z"
        fill={color ?? '#FAFAFA'}
      />
      <path
        d="M10.8979 8.94827L8.94444 9.96243H8.92655C8.0393 10.0189 7.47445 10.1881 7.25684 10.855C7.17286 11.1156 7.09509 11.3468 7.09509 11.3468L5.94495 14.8658H7.34118L7.47226 14.4654L9.14453 13.2626L8.62058 14.8658H10.0168L11.2327 11.1448C11.2831 10.993 11.4948 10.3756 10.9486 10.1033C10.7891 10.0338 10.5229 9.97408 10.2191 9.97408L9.83644 11.1448H10.5305L7.94327 13.0227L8.32994 11.8397H7.60955L10.8979 8.94827Z"
        fill={color ?? '#FAFAFA'}
      />
      <path
        d="M17.6333 9.97408H16.2561C16.0282 9.97408 15.8811 10.1241 15.8811 10.1241L14.2552 11.8397L13.8904 10.301C13.8671 10.2067 13.8506 10.1368 13.7842 10.0694C13.7232 10.0188 13.6659 9.97408 13.4691 9.97408H12.4569L10.8588 14.8658H12.2547L13.1737 12.053L13.5381 14.0343L15.5711 12.037L14.647 14.8654H16.0344L17.6333 9.97408Z"
        fill={color ?? '#FAFAFA'}
      />
    </svg>
  )
}
