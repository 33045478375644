import * as TooltipComponent from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { TextS, TextXs } from '../Typo/Text'

export interface TooltipProps {
  text: string
  children: ReactNode
  textSize?: 's' | 'xs'
  className?: string
}

export function Tooltip({
  text,
  children,
  textSize = 's',
  className,
}: TooltipProps) {
  const getTextComponent = () => {
    if (textSize === 's') {
      return <TextS>{text}</TextS>
    } else if (textSize === 'xs') {
      return <TextXs>{text}</TextXs>
    }
  }

  return (
    <TooltipComponent.Provider>
      <TooltipComponent.Root>
        <TooltipComponent.Trigger asChild>{children}</TooltipComponent.Trigger>
        <TooltipComponent.Portal>
          <TooltipComponent.Content
            className={clsx(
              'max-w-[340px] rounded-sm p-[10px_15px] text-[15px] leading-none text-white bg-neutral-700 shadow-[0_10px_38px_-10px_rgba(0,0,0,0.35),0_10px_20px_-15px_rgba(0,0,0,0.20)] select-none transition duration-400 ease-in-out',
              className
            )}
            sideOffset={5}
          >
            {getTextComponent()}
            <TooltipComponent.Arrow className="fill-neutral-700" />
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      </TooltipComponent.Root>
    </TooltipComponent.Provider>
  )
}
