import { Accordion } from '@radix-ui/react-accordion'
import { NavLink } from '@remix-run/react'
import clsx from 'clsx'
import { ReactElement, useState } from 'react'

import { TensetLogoWithLabel } from '../../assets/icons'
import { useNavigationAccordion, useOverlayMode } from '../../hooks'
import { isExternalLink } from '../../utils'
import { Button, ButtonVariant } from '../Button'
import { Text } from '../Typo/Text'

import { HrefType, NavigationData } from './data'
import { Hamburger } from './hamburger'
import NavigationItems from './items'
import Language, { LocaleData } from './language'

export interface NavigationProps {
  logo?: ReactElement
  logoLink?: string
  data: NavigationData[]
  rightContent?: ReactElement | boolean
  bottomContent?: ReactElement
  localeData: LocaleData
}

/*
 * Safe cache during in production environment
 */
const cachedRoutes: Record<string, unknown> = {}

/*
 * Prefer performance rather than FP concisness here
 */
function resolveRoutesInternal(
  routeWithLocale: (routePath: string) => string,
  currentLocale: string,
  data: NavigationData[]
): NavigationData[] {
  const temporary = []

  for (const route of data) {
    temporary.push({
      ...route,
      href: route.href && {
        type: route.href.type,
        url:
          route.href.type === HrefType.Internal && route.href.withLocale
            ? routeWithLocale(route.href.url)
            : route.href.url,
        withLocale: route.href.withLocale,
      },
      subItems:
        route.subItems &&
        resolveRoutesInternal(routeWithLocale, currentLocale, route.subItems),
    })
  }

  return temporary
}

function resolveRoutes(
  routeWithLocale: (routePath: string) => string,
  currentLocale: string,
  data: NavigationData[]
): NavigationData[] {
  const resolvedRoutes = resolveRoutesInternal(
    routeWithLocale,
    currentLocale,
    data
  )

  cachedRoutes[currentLocale] = resolvedRoutes

  return resolvedRoutes
}

export function Navigation({
  logo = <TensetLogoWithLabel />,
  logoLink = '/',
  data,
  rightContent,
  bottomContent,
  localeData,
}: NavigationProps) {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

  const { value, onValueChange, ref } = useNavigationAccordion()

  const { routeWithLocale, currentLocale, supportedLocales } = localeData

  const allRoutesData = resolveRoutes(routeWithLocale, currentLocale, data)

  useOverlayMode(isMobileMenuOpened)

  const externalLink = isExternalLink(logoLink)

  return (
    <Accordion
      value={value}
      onValueChange={onValueChange}
      type="single"
      collapsible
    >
      <nav
        ref={ref}
        className={clsx(
          'fixed top-0 left-0 z-[999] flex w-full flex-col items-center gap-x-6 gap-y-2 bg-neutral-800 font-semibold text-white',
          isMobileMenuOpened
            ? 'h-screen w-screen'
            : 'justify-between xl:flex-row'
        )}
      >
        <div
          className={clsx(
            'flex h-14 w-full items-center justify-between px-4 py-6 md:py-8 xl:pr-6',
            isMobileMenuOpened ? 'border-b border-neutral-400' : 'xl:w-auto'
          )}
        >
          {isMobileMenuOpened ? (
            <Text className="text-neutral-100">Menu</Text>
          ) : externalLink ? (
            <a href={logoLink} rel="noreferrer">
              {logo}
            </a>
          ) : (
            <NavLink to={routeWithLocale(logoLink)}>{logo}</NavLink>
          )}

          <Button
            variant={ButtonVariant.Ghost}
            className={clsx(!isMobileMenuOpened && 'xl:hidden')}
            usePadding={false}
            onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
          >
            <Hamburger isMobileMenuOpened={isMobileMenuOpened} />
          </Button>
        </div>

        <div
          className={clsx(
            'flex w-full items-center justify-between overflow-y-auto',
            isMobileMenuOpened ? 'flex-col gap-8 p-4' : 'hidden xl:flex xl:pr-4'
          )}
        >
          <NavigationItems
            items={allRoutesData}
            isMobileMenuOpened={isMobileMenuOpened}
            closeMobileMenu={() => setIsMobileMenuOpened(false)}
          />

          <div className="flex w-full flex-col-reverse gap-5 xl:w-auto xl:flex-row xl:items-center xl:justify-center">
            {rightContent && <div>{rightContent}</div>}

            {supportedLocales.length > 0 && (
              <div
                className={clsx(
                  'flex flex-col',
                  isMobileMenuOpened && 'w-full'
                )}
              >
                <Language data={localeData} />
              </div>
            )}
          </div>
        </div>

        {bottomContent && (
          <div className="fixed inset-x-0 top-14 md:top-16">
            {bottomContent}
          </div>
        )}
      </nav>
    </Accordion>
  )
}
