import { AssetProps } from './index'

export default function OKXLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_159_217)">
        <path
          d="M15.4667 8H8.53337C8.23883 8 8 8.23872 8 8.53327V15.4667C8 15.7612 8.23883 16.0001 8.53337 16.0001H15.4667C15.7612 16.0001 16.0001 15.7612 16.0001 15.4667V8.53327C16.0001 8.23872 15.7612 8 15.4667 8Z"
          fill={color ?? '#FAFAFA'}
        />
        <path
          d="M7.46663 0H0.533372C0.23883 0 0 0.238719 0 0.533369V7.46663C0 7.76117 0.23883 8 0.533372 8H7.46663C7.76128 8 8 7.76117 8 7.46663V0.533369C8 0.238719 7.76128 0 7.46663 0Z"
          fill={color ?? '#FAFAFA'}
        />
        <path
          d="M23.4668 0H16.5334C16.2388 0 16 0.238721 16 0.533373V7.4667C16 7.76124 16.2388 8.00007 16.5334 8.00007H23.4668C23.7614 8.00007 24.0001 7.76124 24.0001 7.4667V0.533373C24.0001 0.238721 23.7614 0 23.4668 0Z"
          fill={color ?? '#FAFAFA'}
        />
        <path
          d="M7.46663 16H0.533372C0.23883 16 0 16.2387 0 16.5333V23.4666C0 23.7612 0.23883 24 0.533372 24H7.46663C7.76128 24 8 23.7612 8 23.4666V16.5333C8 16.2387 7.76128 16 7.46663 16Z"
          fill={color ?? '#FAFAFA'}
        />
        <path
          d="M23.4667 16H16.5334C16.2388 16 16 16.2387 16 16.5333V23.4666C16 23.7612 16.2388 24 16.5334 24H23.4667C23.7613 24 24 23.7612 24 23.4666V16.5333C24 16.2387 23.7613 16 23.4667 16Z"
          fill={color ?? '#FAFAFA'}
        />
      </g>
      <defs>
        <clipPath id="clip0_159_217">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
