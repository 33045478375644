import { AssetProps } from './index'

export default function Blur({ size, color, className }: AssetProps) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 366.000000 366.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,366.000000) scale(0.100000,-0.100000)"
        fill={color ?? '#fc7b03'}
        stroke="none"
      >
        <path
          d="M1599 3645 c-771 -105 -1386 -666 -1553 -1416 -134 -607 38 -1220
469 -1668 547 -568 1388 -721 2100 -382 318 152 584 388 767 683 328 528 367
1187 104 1743 -188 396 -485 693 -881 881 -307 145 -676 204 -1006 159z m-15
-831 c51 -20 -19 -24 -374 -24 -340 0 -390 2 -390 15 0 13 48 15 374 15 206 0
381 -3 390 -6z m566 -9 c0 -12 -18 -15 -100 -15 -82 0 -100 3 -100 15 0 12 18
15 100 15 82 0 100 -3 100 -15z m-460 -101 c12 -12 6 -14 -50 -14 -294 -1
-797 -1 -807 -1 -7 1 -13 8 -13 16 0 19 56 21 500 17 291 -3 358 -6 370 -18z
m460 1 c0 -12 -18 -15 -100 -15 -82 0 -100 3 -100 15 0 12 18 15 100 15 82 0
100 -3 100 -15z m-390 -101 c0 -8 -6 -14 -12 -14 -27 -1 -164 -2 -180 -1 -10
1 -18 7 -18 15 0 18 31 22 133 18 58 -3 77 -7 77 -18z m-736 7 c11 -17 -19
-27 -83 -30 -78 -3 -121 6 -121 25 0 11 20 14 99 14 55 0 102 -4 105 -9z
m1126 -6 c0 -11 -11 -15 -37 -16 -21 0 -47 -2 -58 -5 -36 -8 -105 6 -105 22 0
11 20 14 100 14 82 0 100 -3 100 -15z m-1132 -101 c-2 -13 -19 -17 -88 -19
-94 -2 -110 1 -110 21 0 11 21 14 101 14 87 0 100 -2 97 -16z m740 -1 c-3 -16
-16 -18 -101 -17 -53 0 -99 3 -103 6 -19 19 16 28 107 28 90 0 100 -2 97 -17z
m390 0 c-3 -16 -15 -18 -98 -17 -79 2 -96 5 -98 18 -3 14 10 16 98 16 91 0
101 -2 98 -17z m-438 -93 c0 -26 -54 -29 -464 -29 -417 0 -426 0 -426 19 0 20
9 20 445 20 279 0 445 -4 445 -10z m438 -7 c-3 -15 -17 -18 -97 -21 -52 -1
-98 1 -104 7 -22 22 8 31 104 31 90 0 100 -2 97 -17z m-450 -95 c7 -7 12 -16
12 -20 0 -5 -199 -7 -442 -6 -413 3 -443 4 -446 21 -3 16 23 17 430 17 324 0
437 -3 446 -12z m450 -5 c-3 -15 -17 -18 -97 -21 -67 -2 -97 1 -102 10 -15 23
3 28 102 28 90 0 100 -2 97 -17z m-1123 -103 c0 -12 -18 -15 -100 -15 -88 0
-100 2 -103 18 -3 16 5 17 100 15 84 -2 103 -6 103 -18z m735 0 c0 -20 -5 -21
-102 -18 -89 3 -103 5 -106 21 -3 15 7 17 102 17 100 0 106 -1 106 -20z m388
3 c-3 -15 -17 -18 -101 -21 -92 -3 -97 -2 -97 18 0 19 6 20 101 20 90 0 100
-2 97 -17z m-1128 -103 c0 -19 -7 -20 -94 -20 -53 0 -98 4 -101 10 -14 23 11
30 101 30 87 0 94 -1 94 -20z m724 14 c9 -3 16 -12 16 -19 0 -9 -28 -13 -105
-14 -83 -2 -105 1 -105 12 0 7 3 17 7 20 8 9 166 9 187 1z m452 -11 c17 -17
12 -18 -111 -21 -71 -1 -133 2 -138 7 -23 23 8 31 118 31 95 0 116 -3 131 -17z
m-503 -90 c4 -3 -1 -12 -11 -19 -25 -20 -862 -20 -862 -1 0 8 3 17 7 20 8 9
858 9 866 0z m1190 1 c3 -3 3 -12 0 -20 -4 -12 -73 -14 -420 -14 -400 0 -453
4 -453 30 0 11 863 14 873 4z m-1283 -104 c0 -26 -52 -30 -404 -30 -353 0
-388 3 -371 30 8 13 775 14 775 0z m1275 -10 c0 -13 -49 -15 -369 -18 -363 -2
-396 0 -396 29 0 5 164 8 383 7 332 -3 382 -5 382 -18z m-170 -154 c8 -3 15
-10 15 -16 0 -16 -772 -9 -777 7 -3 9 24 13 104 15 175 5 642 1 658 -6z
m-1685 -10 c0 -20 -14 -22 -110 -21 -71 1 -85 4 -88 18 -3 15 8 17 97 17 80 0
101 -3 101 -14z m740 0 c0 -20 -16 -23 -105 -21 -100 3 -95 2 -95 20 0 12 18
15 100 15 79 0 100 -3 100 -14z m1058 -99 c13 -10 7 -13 -34 -17 -88 -9 -834
4 -837 15 -4 18 52 20 456 17 302 -1 404 -5 415 -15z m-1793 2 c11 -17 4 -19
-92 -22 -93 -4 -121 2 -109 22 9 15 192 15 201 0z m735 -4 c0 -8 -3 -15 -7
-16 -70 -5 -181 -3 -190 4 -26 19 1 27 98 27 81 0 99 -3 99 -15z m-740 -100
c0 -18 3 -17 -85 -21 -85 -4 -115 2 -115 21 0 12 18 15 100 15 82 0 100 -3
100 -15z m738 -2 c-3 -15 -17 -18 -100 -21 -93 -3 -118 3 -103 28 3 6 50 10
106 10 90 0 100 -2 97 -17z m392 2 c0 -13 -16 -15 -102 -13 -70 2 -103 7 -106
16 -3 9 22 12 102 12 89 0 106 -3 106 -15z m730 0 c0 -13 -16 -15 -102 -13
-70 2 -103 7 -106 16 -3 9 22 12 102 12 89 0 106 -3 106 -15z m-1860 -99 c0
-18 -14 -22 -84 -25 -73 -3 -116 6 -116 25 0 11 20 14 100 14 79 0 100 -3 100
-14z m738 -3 c-3 -15 -17 -18 -92 -21 -85 -3 -124 7 -110 29 3 5 50 9 105 9
90 0 100 -2 97 -17z m390 1 c-2 -14 -19 -17 -100 -17 -54 0 -101 2 -104 6 -19
18 16 27 107 27 88 0 100 -2 97 -16z m732 1 c0 -12 -18 -15 -100 -15 -82 0
-100 3 -100 15 0 12 18 15 100 15 82 0 100 -3 100 -15z m-1860 -104 c0 -18 -8
-19 -97 -18 -84 2 -98 4 -101 20 -3 15 7 17 97 17 93 0 101 -2 101 -19z m735
-1 c0 -12 -19 -16 -97 -18 -93 -3 -98 -2 -98 18 0 20 5 21 98 18 78 -2 97 -6
97 -18z m1075 11 c0 -6 -4 -12 -9 -16 -6 -3 -187 -6 -403 -6 -507 -1 -478 -2
-478 16 0 13 56 15 445 15 271 0 445 -4 445 -9z m-1810 -111 c0 -19 -7 -20
-100 -20 -93 0 -100 1 -100 20 0 19 7 20 100 20 93 0 100 -1 100 -20z m738 3
c-3 -15 -17 -18 -97 -21 -52 -1 -98 1 -104 7 -22 22 8 31 104 31 90 0 100 -2
97 -17z m1070 -5 c3 -17 -23 -18 -437 -18 -435 0 -441 0 -441 20 0 20 5 20
437 18 408 -3 438 -4 441 -20z m-1803 -98 c0 -12 -18 -16 -94 -18 -92 -3 -120
5 -106 27 4 8 40 11 103 9 78 -2 97 -6 97 -18z m730 0 c0 -12 -17 -16 -80 -18
-97 -4 -127 2 -118 23 8 23 198 17 198 -5z m393 3 c-3 -16 -17 -18 -101 -20
-53 0 -100 3 -103 8 -13 22 13 29 107 29 90 0 100 -2 97 -17z m737 -3 c0 -12
-18 -15 -100 -15 -88 0 -100 2 -103 18 -3 16 5 17 100 15 84 -2 103 -6 103
-18z m-1817 -92 c7 -7 12 -16 12 -20 0 -4 -58 -8 -130 -8 -102 0 -130 3 -130
13 0 8 3 17 7 20 12 13 228 8 241 -5z m676 6 c9 -3 16 -12 16 -20 0 -11 -26
-14 -130 -14 -126 0 -151 7 -112 29 18 11 200 15 226 5z m401 -14 c0 -12 -18
-16 -94 -18 -92 -3 -120 5 -106 27 4 8 40 11 103 9 78 -2 97 -6 97 -18z m735
0 c0 -20 -5 -21 -97 -18 -84 3 -98 6 -101 21 -3 15 7 17 97 17 95 0 101 -1
101 -20z m-1192 -88 c9 -3 10 -8 1 -18 -9 -12 -81 -14 -398 -14 -382 0 -411 2
-411 32 0 8 785 9 808 0z m457 -12 c0 -12 -18 -16 -94 -18 -92 -3 -120 5 -106
27 4 8 40 11 103 9 78 -2 97 -6 97 -18z m733 3 c-3 -15 -17 -18 -101 -21 -92
-3 -97 -2 -97 18 0 19 6 20 101 20 90 0 100 -2 97 -17z m-1278 -90 c0 -2 -6
-11 -14 -19 -20 -19 -555 -21 -589 -2 -21 12 -21 13 13 18 42 7 590 10 590 3z
m545 -13 c0 -12 -17 -15 -95 -15 -83 0 -95 2 -98 18 -3 16 5 17 95 15 79 -2
98 -6 98 -18z m739 12 c13 -22 -25 -33 -108 -31 -82 1 -104 8 -92 28 8 12 193
14 200 3z"
        />
      </g>
    </svg>
  )
}
