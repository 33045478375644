import { AssetProps } from './index'

export default function ChevronUp24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 15.2929L19.2929 16.7071L12 9.41421L4.70711 16.7071L3.29289 15.2929L12 6.58579L20.7071 15.2929Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
