import { AssetProps } from './index'

export default function ChevronDown16({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5.25754L2.13694 4L8 10.4849L13.8631 4L15 5.25754L8 13L1 5.25754Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
