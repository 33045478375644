import { useLocation } from '@remix-run/react'
import { useCallback, useEffect, useState } from 'react'

import { useOutsideClick } from '.'

export function useNavigationAccordion() {
  const [value, setValue] = useState<string>('')
  const location = useLocation()

  function onValueChange(newValue: string) {
    setValue(newValue === value ? '' : newValue)
  }

  const handleOutsideClick = useCallback(() => {
    setValue('')
  }, [setValue])

  const { ref } = useOutsideClick(handleOutsideClick)

  useEffect(() => {
    setValue('')
  }, [location.pathname])

  return { value, onValueChange, ref }
}
