import { AssetProps } from './index'

export default function ChevronRight24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 20.7071L7.29289 19.2929L14.5858 12L7.29289 4.70711L8.70711 3.29289L17.4142 12L8.70711 20.7071Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
