import clsx from 'clsx'

interface HamburgerProps {
  isMobileMenuOpened: boolean
}

const lineAnimations = (isMobileMenuOpened: boolean) => [
  isMobileMenuOpened ? 'rotate-45' : '-translate-y-1.5',
  isMobileMenuOpened && 'opacity-0',
  isMobileMenuOpened ? '-rotate-45' : 'translate-y-1.5',
]

export function Hamburger({ isMobileMenuOpened }: HamburgerProps) {
  return (
    <div className="relative h-10 w-6 xl:w-10">
      <div className="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
        {[0, 1, 2].map(index => (
          <div
            key={index}
            className={clsx(
              'absolute block h-0.5 w-5 transform bg-white transition duration-500 ease-in-out',
              lineAnimations(isMobileMenuOpened)[index]
            )}
          />
        ))}
      </div>
    </div>
  )
}
