import clsx from 'clsx'
import { FC } from 'react'

export interface ImageProps {
  alt?: string
  src: string
  width?: string
  height?: string
  ratio?: number
  isCircle?: boolean
  isCircleOnMobile?: boolean
  className?: string
}

export const Image: FC<ImageProps> = ({
  src,
  alt = '',
  width = 'auto',
  height = 'auto',
  ratio,
  isCircle,
  isCircleOnMobile,
  className,
}) => {
  const roundness = isCircle
    ? 'rounded-full'
    : isCircleOnMobile
      ? 'rounded-full sm:rounded-md'
      : 'rounded-md'

  return ratio ? (
    <div
      className={clsx('overflow-hidden', roundness, className)}
      style={{ aspectRatio: ratio, width: width, height: height }}
    >
      <img src={src} alt={alt} className="h-full w-full object-cover" />
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      key={src}
      style={{ width: width, height: height }}
      className={clsx(roundness, className)}
    />
  )
}
