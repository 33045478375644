import { defaultLocale } from '../Formatters/defaults'

import { GraphProps } from './index'

const priceFormatter = new Intl.NumberFormat(defaultLocale, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 5,
})

const dateFormatter = new Intl.DateTimeFormat(defaultLocale, {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
})

const compactNumberFormatter = new Intl.NumberFormat(defaultLocale, {
  notation: 'compact',
})

const monthNameFormatter = new Intl.DateTimeFormat(defaultLocale, {
  month: 'short',
})

export const graphTokenPriceArguments = (
  data: GraphProps['data']
): Partial<GraphProps> => {
  if (!data || data.length === 0) return {}

  const calculateGraphPriceBalance = data.reduce((accumulator, { price }) => {
    return accumulator + (price as number)
  }, 0)

  const graphColor = calculateGraphPriceBalance > 0 ? '#67DB8A' : '#FF8585'

  return {
    graphColor,
    highlightLatestY: true,
    highlightLatestYFormatter: (value: number) => priceFormatter.format(value),
    xAxisProps: {
      scale: 'time',
      type: 'number',
      domain: ['dataMin', 'dataMax'],
      tickFormatter: (value: number) => dateFormatter.format(value),
      tick: { fontSize: 10 },
      minTickGap: 20,
      padding: { left: 5, right: 5 },
    },
    yAxisProps: {
      tickFormatter: (value: number) => priceFormatter.format(value),
      domain: ['dataMin', 'dataMax'],
      orientation: 'right',
      padding: { top: 0, bottom: 15 },
      tick: false,
    },
    tooltipProps: {
      labelFormatter: (value: number) => dateFormatter.format(value),
      formatter: (value: number) => [priceFormatter.format(value)],
    },
  }
}

export const graphBurnedMonthlyArguments: Partial<GraphProps> = {
  xAxisProps: {
    tickFormatter: (value: Date) => monthNameFormatter.format(value),
  },
  yAxisProps: {
    tickFormatter: (value: number) => compactNumberFormatter.format(value),
    domain: ['dataMin', 'dataMax'],
  },
  tooltipProps: {
    labelFormatter: (value: Date) => monthNameFormatter.format(value),
    formatter: (value: number) => [compactNumberFormatter.format(value)],
  },
}
