import clsx from 'clsx'

export interface VideoProps {
  youtubeId?: string
  src?: string
  type?: string
  controls?: boolean
  autoPlay?: boolean
  muted?: boolean
  playsInline?: boolean
  loop?: boolean
  className?: string
}

export function Video({
  youtubeId,
  src,
  type = 'video/mp4',
  controls = true,
  autoPlay = false,
  className,
  ...props
}: VideoProps) {
  if (youtubeId) {
    const youtubeSource = `https://www.youtube.com/embed/${youtubeId}?autoplay=${
      autoPlay ? 1 : 0
    }&controls=${controls ? 1 : 0}`

    return (
      <div
        className={clsx(
          'relative h-[200px] sm:h-[300px] xl:h-[400px]',
          className
        )}
      >
        <iframe
          width="100%"
          height="100%"
          src={youtubeSource}
          title="YouTube video player"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    )
  }

  return (
    <div className={clsx('sm:min-h-auto relative h-full w-full', className)}>
      <video
        className="w-full h-full"
        controls={controls}
        autoPlay={autoPlay}
        {...props}
      >
        <source src={src} type={type} />

        <track default kind="captions" />
      </video>
    </div>
  )
}
