import { AssetProps } from './index'

export default function Duplicate16({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.375 1.6H13.25V12H15V0H5.375V1.6ZM11.5 3.2H1V16H11.5V3.2ZM2.75 14.4V4.8H9.75V14.4H2.75Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
