import clsx from 'clsx'
import { HTMLAttributes } from 'react'

import { TextNumeric, TextS } from '../Typo/Text'

export enum DataPointColor {
  NEUTRAL800 = 'bg-neutral-800',
  NEUTRAL900 = 'bg-neutral-900',
}

export interface DataPointProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  color?: DataPointColor
}

const { NEUTRAL800 } = DataPointColor

export function DataPoint({
  label,
  children,
  className,
  color = NEUTRAL800,
}: DataPointProps) {
  return (
    <div
      className={clsx(
        'flex flex-col gap-1 rounded-[10px] py-4 px-6 text-white',
        color,
        className
      )}
    >
      <TextS isBold>{label}</TextS>

      <TextNumeric tag="div" className="flex flex-col gap-2">
        {children}
      </TextNumeric>
    </div>
  )
}
