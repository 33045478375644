import { NavLink } from '@remix-run/react'
import clsx from 'clsx'

import { LinkOut } from '../../assets/icons'
import { Button, ButtonJustify, ButtonVariant } from '../Button'

import { HrefType, NavigationData } from './data'
import { Dropdown } from './dropdown'

export interface NavigationItemsProps {
  isMobileMenuOpened: boolean
  closeMobileMenu: () => void
  items: NavigationData[]
}

export default function NavigationItems({
  isMobileMenuOpened,
  closeMobileMenu,
  items,
}: NavigationItemsProps) {
  return (
    <ul
      className={clsx(
        'flex gap-4',
        isMobileMenuOpened ? 'w-full flex-col' : 'items-center'
      )}
    >
      {items.map(item => {
        return (
          <li
            key={item.label}
            className={clsx(!isMobileMenuOpened && 'xl:first-of-type:hidden')}
          >
            <NavigationItem closeMobileMenu={closeMobileMenu} item={item} />
          </li>
        )
      })}
    </ul>
  )
}

interface NavigationItemProps {
  item: NavigationData
  closeMobileMenu: () => void
}

function NavigationItem({ item, closeMobileMenu }: NavigationItemProps) {
  const { label, href, subItems, onClick } = item || {}

  const hasHref = href !== undefined
  const hasSubItems = subItems && subItems.length > 0

  // Link
  if (hasHref) {
    const { type, url } = href

    const componentName = type === HrefType.Internal ? NavLink : 'a'

    const linkAttributes =
      type === HrefType.Internal ? { to: url } : { to: url, target: '_blank' }

    return (
      <Button
        variant={ButtonVariant.Ghost}
        justify={ButtonJustify.Start}
        className="w-full"
        onClick={closeMobileMenu}
        as={componentName}
        {...linkAttributes}
      >
        <span>{label}</span>
        {type === HrefType.External && <LinkOut className="h-3.5 w-3.5" />}
      </Button>
    )
  }

  // Dropdown
  if (hasSubItems) {
    return (
      <Dropdown buttonLabel={label} fullWidthButton>
        <div className="flex flex-col gap-1">
          {subItems.map(subItem => (
            <NavigationItem
              closeMobileMenu={closeMobileMenu}
              key={subItem.label}
              item={subItem}
            />
          ))}
        </div>
      </Dropdown>
    )
  }

  return (
    <Button
      variant={ButtonVariant.Ghost}
      justify={ButtonJustify.Start}
      onClick={onClick}
    >
      <span>{label}</span>
    </Button>
  )
}
