import { HTMLAttributes, ReactElement } from 'react'

import { DateFormatter } from '../Formatters'
import { TextL, TextSNumeric } from '../Typo/Text'
import { defaultDateFormatterOptions } from '../Formatters/defaults'

export interface PostProps extends HTMLAttributes<HTMLDivElement> {
  date: Date
  title: string
  description?: ReactElement
  image: string
}

export function Post({ date, title, description, image }: PostProps) {
  return (
    <article className="text-white sm:max-w-[700px]">
      <TextSNumeric className="mb-2 text-neutral-100 sm:text-base">
        <DateFormatter
          date={date}
          options={defaultDateFormatterOptions.dateOnly}
        />
      </TextSNumeric>

      <div className="grid grid-cols-4 gap-2 sm:grid-cols-3 sm:gap-4">
        <div className="col-span-3 sm:col-span-2">
          <TextL isBold className="mb-2">
            {title}
          </TextL>

          {description && (
            <div className="h-[70px] truncate whitespace-normal text-sm sm:text-base">
              {description}
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <img
            src={image}
            alt={title}
            className="h-16 w-16 rounded object-cover md:h-[108px] md:w-[216px] md:rounded-lg"
            key={image}
          />
        </div>
      </div>
    </article>
  )
}
