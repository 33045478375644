import { AssetProps } from './index'

export default function LinkOut24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9752 13.8257H19V5.17159H10.0867L10.0867 7.13751L15.9628 7.13752L4.99997 17.7815L6.43173 19.1716L16.9752 8.93481V13.8257Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
