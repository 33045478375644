import { AssetProps } from './index'

export default function BinanceLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_486_1265)">
        <g clipPath="url(#clip1_486_1265)">
          <path
            d="M7.33907 10.085L11.9999 5.42436L16.6631 10.0874L19.3751 7.37544L11.9999 0L4.62719 7.37304L7.33907 10.085ZM6.10352e-05 11.9996L2.71215 9.28754L5.42405 11.9995L2.71199 14.7115L6.10352e-05 11.9996ZM7.33907 13.915L11.9999 18.5756L16.6629 13.9127L19.3764 16.6232L19.3751 16.6247L11.9999 24L4.62695 16.6272L4.62311 16.6234L7.33907 13.915ZM18.5761 12.0011L21.2882 9.28904L24.0001 12.0009L21.2881 14.713L18.5761 12.0011Z"
            fill={color ?? '#F3BA2F'}
          />
          <path
            d="M14.7507 11.9986H14.7519L11.9999 9.24661L9.96613 11.2804H9.96601L9.73237 11.5141L9.25045 11.9962L9.24661 11.9999L9.25045 12.0039L11.9999 14.7534L14.7519 12.0015L14.7532 11.9999L14.7507 11.9986Z"
            fill={color ?? '#F3BA2F'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_486_1265">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
        <clipPath id="clip1_486_1265">
          <rect width="24" height="24" fill={color ?? 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}
