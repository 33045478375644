import {
  defaultPrecision,
  defaultCurrencies,
  defaultLocale,
  defaultDateFormatterOptions,
} from './defaults'

type IntlLocales = string | string[]

interface FormatNumber {
  value: number
  locales?: IntlLocales
  options?: Intl.NumberFormatOptions
  prefix?: string
  suffix?: string
}

const formatNumber = ({
  value,
  locales,
  options,
  prefix,
  suffix,
}: FormatNumber) => {
  const numberFormatted = new Intl.NumberFormat(locales, options).format(value)

  return [prefix, numberFormatted, suffix].filter(Boolean).join('')
}

export interface FormatDate {
  date: Date
  locales?: IntlLocales
  options?: Intl.DateTimeFormatOptions
}

const formatDate = ({ date, locales, options }: FormatDate) =>
  new Intl.DateTimeFormat(locales, options).format(date)

export interface NumberFormatterProps extends FormatNumber {
  precision?: [number, number]
}

export function NumberFormatter({
  locales = defaultLocale,
  precision = defaultPrecision.number,
  ...props
}: NumberFormatterProps) {
  const [minimumFractionDigits, maximumFractionDigits] = precision

  const options: Intl.NumberFormatOptions = {
    ...props.options,
    minimumFractionDigits,
    maximumFractionDigits,
  }

  const formattedValue = formatNumber({ locales, options, ...props })

  return <>{formattedValue}</>
}

type CurrencyType = Intl.NumberFormatOptions['currency']

export interface CurrencyFormatterProps extends NumberFormatterProps {
  currency?: CurrencyType
}

export function CurrencyFormatter({
  locales = defaultLocale,
  precision = defaultPrecision.currency,
  currency = defaultCurrencies.currency,
  ...props
}: CurrencyFormatterProps) {
  const [minimumFractionDigits, maximumFractionDigits] = precision

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
    currency,
  }

  const formattedValue = formatNumber({ locales, options, ...props })

  return <>{formattedValue}</>
}

export interface CryptoCurrencyFormatterProps extends NumberFormatterProps {
  currency?: string
}

export function CryptoCurrencyFormatter({
  locales = defaultLocale,
  precision = defaultPrecision.cryptoCurrency,
  currency = defaultCurrencies.cryptoCurrency,
  ...props
}: CryptoCurrencyFormatterProps) {
  const [minimumFractionDigits, maximumFractionDigits] = precision

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
    // @ts-expect-error wrong typescript version
    trailingZeroDisplay: 'stripIfInteger',
  }

  const formattedValue = formatNumber({ locales, options, ...props })

  return (
    <>
      {formattedValue} {currency}
    </>
  )
}

export function DateFormatter({
  date,
  locales = defaultLocale,
  options = defaultDateFormatterOptions.full,
}: FormatDate) {
  const formattedDate = formatDate({ date, locales, options }).replaceAll(
    '/',
    '-'
  )

  return <>{formattedDate}</>
}
