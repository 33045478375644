import { AssetProps } from './index'

export default function Duplicate24({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4H18V17H20V2H9V4ZM16 6H4V22H16V6ZM6 20V8H14V20H6Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
