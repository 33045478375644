export default function loading2() {
  return (
    <div className="lds2">
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
