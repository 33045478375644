import { AssetProps } from './index'

export default function GateLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '24'}
      height={size ?? '24'}
      className={className}
    >
      <g transform="matrix(0.009200001 0 0 0.009200001 0  -0)">
        <g>
          <path d="M0 0L2500 0L2500 2500L0 2500z" fill="none" />
          <g>
            <path
              d="M1250 1937.5C 870.3 1937.5 562.5 1629.7 562.5 1250C 562.5 870.3 870.3 562.5 1250 562.5L1250 562.5L1250 0C 559.6 0 0 559.6 0 1250C 0 1940.3 559.6 2500 1250 2500C 1940.3 2500 2500 1940.4 2500 1250L2500 1250L1937.5 1250C 1937.5 1629.7 1629.7 1937.5 1250 1937.5z"
              fill={color ?? '#2354E6'}
              fillRule="nonzero"
            />
            <path
              d="M1250 1250L1937.5 1250L1937.5 562.5L1250 562.5L1250 1250"
              fill={color ?? '#17E6A1'}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
