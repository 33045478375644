import { HTMLAttributes } from 'react'

import { TextS } from '../Typo/Text'

export interface PresentationProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  author?: string
}

export function Presentation({ title, children, author }: PresentationProps) {
  return (
    <section className="flex flex-col gap-2 bg-white p-6">
      <header className="text-neutral-400">
        <TextS>{title}</TextS>
      </header>

      <main>{children}</main>

      <div className="text-neutral-400">
        <TextS>{author}</TextS>
      </div>
    </section>
  )
}
