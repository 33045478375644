import { AssetProps } from './index'

export default function InfoIcon({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '17'}
      height={size ? (size * 16) / 17 : '16'}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3209_5193)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 14.5C12.0899 14.5 15 11.5899 15 8C15 4.41015 12.0899 1.5 8.5 1.5C4.91015 1.5 2 4.41015 2 8C2 11.5899 4.91015 14.5 8.5 14.5ZM8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16ZM9.5 5C9.5 5.55228 9.05229 6 8.5 6C7.94772 6 7.5 5.55228 7.5 5C7.5 4.44772 7.94772 4 8.5 4C9.05229 4 9.5 4.44772 9.5 5ZM8.5 7C7.94772 7 7.5 7.44772 7.5 8V11C7.5 11.5523 7.94772 12 8.5 12C9.05228 12 9.5 11.5523 9.5 11V8C9.5 7.44772 9.05228 7 8.5 7Z"
          fill={color ?? '#FAFAFA'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3209_5193">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
