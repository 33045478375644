import { AssetProps } from './index'

export default function LinkOut16({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9752 9.82567H15V1.17159H6.08666L6.08666 3.13751L11.9628 3.13752L0.999969 13.7815L2.43173 15.1716L12.9752 4.93481V9.82567Z"
        fill={color ?? 'white'}
      />
    </svg>
  )
}
