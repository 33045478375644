import clsx from 'clsx'
import { ReactElement, ReactNode } from 'react'

import { H3 } from '../Typo/Header'
import { Text, TextNumeric } from '../Typo/Text'

export interface TileProps {
  title: string
  subtitle?: ReactElement | string
  description?: string
  showDescriptionOnMobile?: boolean
  linkComponent?: (element: ReactElement) => ReactElement
  tag?: ReactElement
  cover: string
  actions?: ReactElement
  children?: ReactNode
  isVideo?: boolean
}

export function Tile({
  title,
  subtitle,
  description,
  showDescriptionOnMobile = false,
  linkComponent,
  children,
  tag,
  cover,
  actions,
  isVideo,
}: TileProps) {
  const componentWithLink = (element: ReactElement) =>
    linkComponent ? linkComponent(element) : element

  return (
    <section className="flex flex-col overflow-hidden rounded-xl bg-neutral-800 text-white md:flex-row">
      <div className="max-h-[200px] w-full min-w-[300px] sm:max-h-[400px] lg:max-h-full lg:max-w-[300px]">
        {componentWithLink(
          <>
            {isVideo ? (
              <div className="sm:min-h-auto relative h-full min-h-[200px] w-full">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="absolute top-0 left-0 grid h-full w-full place-items-center object-cover"
                >
                  <source src={cover} type="video/mp4" />
                </video>
              </div>
            ) : (
              <img
                src={cover}
                key={cover}
                alt={title}
                className="h-full max-h-[200px] w-full object-cover sm:max-h-[400px] lg:max-h-fit"
              />
            )}
          </>
        )}
      </div>

      <div className="flex flex-col gap-6 p-6 md:gap-x-12 md:gap-y-8 md:px-12 md:py-8 lg:grid lg:w-full lg:grid-cols-[2fr,1fr]">
        <header className="flex flex-col gap-6">
          <div className="flex flex-col">
            <span className="flex flex-wrap items-center gap-2 pb-2">
              {componentWithLink(<H3 isBold>{title}</H3>)}

              {tag}
            </span>

            {subtitle && <TextNumeric className="pb-4">{subtitle}</TextNumeric>}

            {description && (
              <Text
                className={clsx(!showDescriptionOnMobile && 'hidden lg:block')}
              >
                {description}
              </Text>
            )}
          </div>

          <div className="hidden gap-2 lg:flex">{actions}</div>
        </header>

        {children && <main className="flex flex-col">{children}</main>}

        {actions && (
          <div className="flex w-full flex-col items-stretch lg:hidden">
            {actions}
          </div>
        )}
      </div>
    </section>
  )
}
