import { AssetProps } from './index'

export default function CoinWLogo({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>英文logo</title>
      <g
        id="组件库"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon"
          transform="translate(-636.000000, -978.000000)"
          fillRule="nonzero"
        >
          <g id="英文logo" transform="translate(636.000000, 978.000000)">
            <path
              d="M2.42385119,6.13013239 L9.719169,13.3548749 L14.7228586,8.41752316 L19.7261372,13.3548749 L23.1644015,9.95188373 L21.4871894,8.27807313 L28,8.27807313 L28,14.7776754 L26.2948412,13.0759748 L19.7261372,19.547687 L14.7228586,14.6103354 L9.74711568,19.5755771 L4.43642351,14.3314353 C4.49231688,16.6746061 5.44250418,19.0177769 7.23150302,20.8031475 C10.697714,24.2623288 16.1760862,24.5129287 19.9497107,21.5840678 L23.0801505,24.7085689 C20.4806977,26.8843997 17.2384713,28 14.0237805,28 C10.4178361,28 6.84024943,26.6329794 4.10106329,23.8993485 C-0.734946206,19.0735569 -1.29387991,11.5695042 2.42385119,6.13013239 Z M4.46420608,3.73101751 C9.69146917,-1.09477413 17.6854541,-1.23463432 23.0524506,3.28477739 L19.8936532,6.3809784 C18.1609588,5.04225803 16.092493,4.37248769 13.9960806,4.37248769 C11.7040415,4.37248769 9.41200232,5.20959807 7.59505679,6.82762867 Z"
              id="形状结合"
              fill={color ?? '#7650FF'}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
