import clsx from 'clsx'
import { HTMLAttributes, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '..'
import { NumberFormatter } from '../Formatters'
import { TextS, Text, TextNumeric } from '../Typo/Text'

export interface PollBarProps extends HTMLAttributes<HTMLButtonElement> {
  name: string
  displayProgress?: boolean
  displayVotes?: boolean
  isSelected?: boolean
  index: string | number
  votes: number
  myVoteWeight?: number
  participants: number
  color?: PollBarColor
  myVoteColor?: PollBarColor
}

export enum PollBarColor {
  Neutral400 = 'bg-neutral-300',
  Neutral500 = 'bg-neutral-500',
  Neutral600 = 'bg-neutral-600',
  Neutral700 = 'bg-neutral-700',
}

export function PollBar({
  name,
  displayProgress = true,
  displayVotes = true,
  isSelected = false,
  index,
  participants,
  votes,
  myVoteWeight = 0,
  onClick,
  color = PollBarColor.Neutral500,
  myVoteColor = PollBarColor.Neutral400,
}: PollBarProps) {
  const [hovered, setHovered] = useState(isSelected)
  const [votesState, setVotesState] = useState(votes)

  useEffect(() => {
    if (!isSelected) {
      setHovered(false)
      setVotesState(votes)
    }
  }, [isSelected, votes])

  const handleMouseEnter = () => {
    if (isSelected) return

    setHovered(true)
    setVotesState(votes + myVoteWeight)
  }

  const handleMouseLeave = () => {
    if (isSelected) return

    setHovered(false)
    setVotesState(votes)
  }
  const progress = (votes / participants) * 100
  const myVotesProgress = (myVoteWeight / participants) * 100

  return (
    <Button
      className={clsx(
        'relative min-h-[56px] w-full !p-3',
        !onClick && 'hover:!cursor-default  hover:!bg-opacity-0',
        isSelected && !displayProgress && '!bg-neutral-300'
      )}
      variant={ButtonVariant.Secondary}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {displayProgress && (
        <div className={'absolute left-0 top-0 bottom-0 flex w-full'}>
          <div
            className={clsx(color)}
            style={{ width: `${progress > 100 ? 100 : progress}%` }}
          />
          {hovered && (
            <div
              className={clsx(myVoteColor)}
              style={{ width: `${progress > 100 ? 0 : myVotesProgress}%` }}
            />
          )}
        </div>
      )}

      <div className="z-10 flex w-full flex-row items-center justify-between gap-3 text-left">
        <div className="flex flex-row items-center gap-3">
          {index && (
            <span
              className={clsx(
                'flex h-[32px] min-w-[32px] items-center justify-center rounded-[2px] border border-neutral-100',
                isSelected && '!border-white !bg-white !text-neutral-900'
              )}
            >
              <TextS isBold>{index}</TextS>
            </span>
          )}

          <Text>{name}</Text>
        </div>

        {displayVotes && (
          <TextNumeric>
            <NumberFormatter value={votesState} />
          </TextNumeric>
        )}
      </div>
    </Button>
  )
}
