import { AssetProps } from './index'

export default function Mail({ size, color, className }: AssetProps) {
  return (
    <svg
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.75"
        y="2.75"
        width="14.5"
        height="10.5"
        stroke={color ?? '#FAFAFA'}
        strokeWidth="1.5"
      />
      <path d="M2 3L8 9L14 3" stroke={color ?? '#FAFAFA'} strokeWidth="1.5" />
    </svg>
  )
}
