import { useEffect, useRef } from 'react'

export const useSwipeDown = (
  onSwipedDown: () => void,
  threshold = 50,
  excludeSelector = ''
) => {
  const touchStartY = useRef<number | null>(null)

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      if (
        excludeSelector &&
        (event.target as HTMLElement).closest(excludeSelector)
      ) {
        const scrollableContent = (event.target as HTMLElement).closest(
          excludeSelector
        )

        const visibleElement = scrollableContent?.querySelector(
          '[data-state="visible"]'
        )

        if (visibleElement) return
      }

      touchStartY.current = event.touches[0].clientY
    }

    const handleTouchMove = (event: TouchEvent) => {
      if (touchStartY.current !== null) {
        const touchEndY = event.touches[0].clientY
        const touchDistance = touchEndY - touchStartY.current

        if (touchDistance > threshold) {
          onSwipedDown()

          touchStartY.current = null
        }
      }
    }

    const handleTouchEnd = () => {
      touchStartY.current = null
    }

    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    }
  }, [onSwipedDown, threshold, excludeSelector])
}
