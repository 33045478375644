import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'

export enum DividerColor {
  NEUTRAL400 = 'border-neutral-400',
  NEUTRAL600 = 'border-neutral-600',
}

export interface DividerProps extends HtmlHTMLAttributes<HTMLDivElement> {
  color?: DividerColor
}

export function Divider({
  className,
  color = DividerColor.NEUTRAL400,
  ...props
}: DividerProps) {
  return (
    <div className={clsx('w-full border-t', color, className)} {...props} />
  )
}
