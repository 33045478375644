import { AssetProps } from './index'

export default function Verification({ size, className }: AssetProps) {
  return (
    <svg
      id="Layer_1"
      width={size ?? '24'}
      height={size ?? '24'}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xmlSpace="preserve"
      className={className}
      fill="currentColor"
    >
      <path
        className="st0"
        d="M19.2,11.7c-2.7,0-4.8,2.2-4.8,4.8s2.2,4.8,4.8,4.8s4.8-2.2,4.8-4.8S21.8,11.7,19.2,11.7z M18.4,19.1l-2.3-2.4&#xA;&#x9;l0.7-0.6l1.6,1.7l3.3-3.5l0.7,0.6L18.4,19.1z M11.3,13.4v-0.9H15c1-1.1,2.5-1.8,4.1-1.8c1,0,1.9,0.2,2.7,0.7V4&#xA;&#x9;c0-0.7-0.6-1.3-1.3-1.3H1.3C0.6,2.7,0,3.3,0,4v11.6c0,0.7,0.6,1.3,1.3,1.3h12.1c0-0.1,0-0.3,0-0.4c0-1.1,0.3-2.2,0.9-3.1H11.3z&#xA;&#x9; M11.3,6.2h7.8v0.9h-7.8V6.2z M11.3,9.3h7.8v0.9h-7.8V9.3z M5.6,13.8c-1.4,0-2.7-0.1-3.9-0.2c0.2-0.6,0.5-1.9,0.9-2&#xA;&#x9;c0.5-0.2,1.6-0.6,2.1-0.8c0.1-0.3,0.1-0.6,0.1-0.6C4.5,10,4.2,9.6,4.1,9.2C4,9.2,3.9,9.1,3.9,8.8c0-0.5,0-0.7,0-0.7&#xA;&#x9;C3.8,7.6,3.8,7.3,3.8,7C3.9,6.3,4.4,6,5,5.9c0.7-0.1,1.3-0.3,1.7,0.5C6.9,6.4,7.2,6.6,7.3,7c0.1,0.3,0.2,0.6,0.1,1.1&#xA;&#x9;c0,0,0,0.3,0,0.7c0,0.3-0.1,0.3-0.3,0.4C7,9.6,6.8,10,6.4,10.3c0,0,0,0.3,0.1,0.6C7,11,8.1,11.4,8.6,11.6c0.4,0.1,0.8,1.5,0.9,2&#xA;&#x9;C8.3,13.8,7,13.8,5.6,13.8z"
      />
    </svg>
  )
}
