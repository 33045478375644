import { ReactElement } from 'react'

import { Tile, TileProps } from '../Tile'
import { TextNumeric, TextS } from '../Typo/Text'

export interface GemInfoItem {
  title: string
  value: ReactElement | string
}

export interface GemInfoProps extends Omit<TileProps, 'children'> {
  items: GemInfoItem[]
}

export function GemInfo({ items, ...props }: GemInfoProps) {
  return (
    <Tile {...props}>
      <div className="flex flex-col gap-2">
        {items.map(({ title, value }) => (
          <div key={title}>
            <TextS isBold>{title}</TextS>

            <TextNumeric>{value}</TextNumeric>
          </div>
        ))}
      </div>
    </Tile>
  )
}
