import { UnicodeChars } from '.'

export function addressShortener(address: string, from = [0, 5], to = -4) {
  const { horizontalEllipsis, enDash } = UnicodeChars

  const [fromStart, fromEnd] = from

  const startPart = address.slice(fromStart, fromEnd)
  const endPart = address.slice(to)
  return address ? `${startPart}${horizontalEllipsis}${endPart}` : enDash
}
