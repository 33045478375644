import clsx from 'clsx'
import { ReactElement, useEffect, useState } from 'react'

import { Button, ButtonVariant } from '../Button'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { ScrollArea } from '../ScrollArea'
import { H3 } from '../Typo/Header'

export interface CheckboxGroupProps {
  title?: string | ReactElement
  name: string
  options: CheckboxProps[]
  checked: string[]
  onCheckedChange: (checked: string[]) => void
  scrollAreaClassName?: string
  className?: string
}

export function CheckboxGroup({
  title,
  name,
  options,
  checked,
  onCheckedChange,
  scrollAreaClassName,
  className,
}: CheckboxGroupProps) {
  const [checkableOptions, setCheckableOptions] = useState(
    options.filter(({ disabled }) => !disabled).map(({ id }) => id)
  )

  useEffect(() => {
    setCheckableOptions(
      options.filter(({ disabled }) => !disabled).map(({ id }) => id)
    )
  }, [options])

  const areAllChecked = checked.length === checkableOptions.length

  function flipCheck() {
    onCheckedChange(areAllChecked ? [] : checkableOptions)
  }

  return (
    <div className={clsx(className, 'flex flex-col gap-2')}>
      <div className="flex flex-wrap gap-2 justify-between">
        {title && (typeof title === 'string' ? <H3>{title}</H3> : title)}

        {checkableOptions && checkableOptions.length > 1 && (
          <Button
            variant={ButtonVariant.Secondary}
            usePadding={false}
            className="px-1"
            onClick={flipCheck}
          >
            {areAllChecked ? 'Deselect all' : 'Select all'}
          </Button>
        )}
      </div>

      <ScrollArea
        className={clsx(
          'max-h-48 has-[div[data-state=visible]]:pr-2',
          scrollAreaClassName
        )}
        type="auto"
      >
        <div className="flex flex-col gap-2">
          {options.map(({ id, label, children, ...props }) => (
            <Checkbox
              {...props}
              key={id}
              id={id}
              name={name}
              label={label}
              checked={checked.includes(id)}
              onCheckedChange={isChecked =>
                onCheckedChange(
                  isChecked
                    ? [...checked, id]
                    : checked.filter(checkedId => checkedId !== id)
                )
              }
            >
              {children}
            </Checkbox>
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}
