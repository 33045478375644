import { AssetProps } from '.'

export default function Website({ size, color, className }: AssetProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '15.167'}
      height={size ?? '15.167'}
      viewBox="0 0 15.167 15.167"
      className={className}
    >
      <path
        id="WWW"
        d="M10.443,2.961C9.862,1.378,9,.269,8.031,0L7.964,0V3.215a16.6,16.6,0,0,0,2.479-.253m-5.719,0A16.592,16.592,0,0,0,7.2,3.215V0L7.136,0C6.167.269,5.305,1.378,4.724,2.961M11.963,7.2h3.2a7.555,7.555,0,0,0-1.6-4.3,16.076,16.076,0,0,1-2.124.631A14.922,14.922,0,0,1,11.963,7.2M13.034,2.29A7.6,7.6,0,0,0,9.817.315a7.462,7.462,0,0,1,1.4,2.49,16.664,16.664,0,0,0,1.822-.515M3.969,7.2H7.2V3.976a17.446,17.446,0,0,1-2.717-.282A14.059,14.059,0,0,0,3.969,7.2m5.846,7.649a7.6,7.6,0,0,0,3.22-1.975,16.617,16.617,0,0,0-1.823-.515,7.455,7.455,0,0,1-1.4,2.49m-1.852-3.66a17.4,17.4,0,0,1,2.717.282,14.076,14.076,0,0,0,.517-3.51H7.964Zm-3.478.282A17.392,17.392,0,0,1,7.2,11.192V7.964H3.969a14.041,14.041,0,0,0,.517,3.51m7.477-3.51a14.9,14.9,0,0,1-.519,3.666,16.022,16.022,0,0,1,2.125.631,7.559,7.559,0,0,0,1.6-4.3Zm-4-3.988V7.2H11.2a14.073,14.073,0,0,0-.517-3.509,17.441,17.441,0,0,1-2.717.282m2.479,8.23a16.643,16.643,0,0,0-2.479-.253v3.215l.065,0C9,14.9,9.861,13.79,10.443,12.206M3.722,3.538A16.139,16.139,0,0,1,1.6,2.906,7.556,7.556,0,0,0,0,7.2H3.2a14.883,14.883,0,0,1,.519-3.665M3.2,7.964H0a7.556,7.556,0,0,0,1.6,4.3,16.137,16.137,0,0,1,2.125-.63A14.883,14.883,0,0,1,3.2,7.964M5.35.316A7.6,7.6,0,0,0,2.133,2.29a16.628,16.628,0,0,0,1.822.515A7.456,7.456,0,0,1,5.35.316M2.132,12.877a7.6,7.6,0,0,0,3.219,1.976,7.459,7.459,0,0,1-1.4-2.49,16.647,16.647,0,0,0-1.822.515m2.592-.671c.582,1.584,1.444,2.693,2.414,2.958l.065,0V11.953a16.593,16.593,0,0,0-2.479.253"
        fill={color ?? '#fff'}
      />
    </svg>
  )
}
